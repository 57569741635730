/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { createRoot } from 'react-dom/client';
import Avatar from 'react-avatar'
import { useTranslation } from 'react-i18next';
import Icons from "../../../../../components/shared/Icons";
import { Issuer, IssuerSkeleton } from "../../../../../components/shared/Cards";
import issuers from "../../../../../services/dummyDataIssuers";
import dummyNetwork from "../../../../../assets/images/polygon.png"

const DropdownModal = ({ isLogin, readOnly, setCurrentIssuer, currentIssuer }) => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filteredIssuers, setFilteredIssuers] = useState([]);
  const searchInputRef = useRef(null);

  const getNetworkImage = (network) => {
    try {
      const networks = {
        'polygon': require('../../../../../assets/images/tokenSymbol/polygon.png'),
        'base': require('../../../../../assets/images/tokenSymbol/base.svg'),
        'ethereum': require('../../../../../assets/images/tokenSymbol/ethereum.png'),
        'mainnet': require('../../../../../assets/images/tokenSymbol/ethereum.png'),
      };
      return networks[network] || null;
    } catch (err) {
      return null;
    }
  };

  const NetworkIcon = ({ network }) => {
    const imgSrc = getNetworkImage(network);

    if (!imgSrc) {
      return (
        <Avatar
          name={network}
          size="12"
          round={true}
          className="right-[-2px] bottom-[-2px] z-10 absolute"
        />
      );
    }

    return (
      <img
        src={imgSrc.default || imgSrc}
        alt={network}
        className="right-[-2px] bottom-[-2px] z-10 absolute rounded-full w-3 h-3"
        onError={(e) => {
          e.target.onerror = null;
          e.target.style.display = 'none';
          const avatarEl = document.createElement('div');
          avatarEl.className = "right-[-2px] bottom-[-2px] z-10 absolute";
          const avatar = document.createElement('div');
          avatarEl.appendChild(avatar);
          e.target.parentNode.appendChild(avatarEl);

          const root = createRoot(avatar);
          root.render(
            <Avatar
              name={network}
              size="12"
              round={true}
            />
          );
        }}
      />
    );
  };

  const getImageUrl = (type, imageName) => {
    try {
      return require(`../../../../../assets/images/${type}/${imageName}`);
    } catch (err) {
      return require(`../../../../../assets/images/issuer/default-icon-day-v3.svg`)
    }
  }

  // Handle modal open/close
  const toggleModal = async (e) => {
    if (readOnly) return;
    if (e) e.preventDefault();

    if (!isOpenModal) {
      setIsOpenModal(true);
      setSearchTerm("");
      setIsLoading(true);
      setFilteredIssuers(issuers);

      // Simulate initial load delay
      await new Promise(resolve => setTimeout(resolve, 800));
      setIsLoading(false);
    } else {
      setIsOpenModal(false);
      setSearchTerm("");
      setFilteredIssuers([]);
    }
  };

  // Handle search with loading state
  useEffect(() => {
    const searchIssuers = async () => {
      if (!isOpenModal) return;

      setIsLoading(true);

      // Simulate search delay
      await new Promise(resolve => setTimeout(resolve, 400));

      const filtered = issuers.filter((issuer) =>
        issuer.coin_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        issuer.company_name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setFilteredIssuers(filtered);
      setIsLoading(false);
    };

    const debounceTimer = setTimeout(searchIssuers, 300);
    return () => clearTimeout(debounceTimer);
  }, [searchTerm, isOpenModal]);

  // Handle ESC key
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsOpenModal(false);
      }
    };

    if (isOpenModal) {
      document.body.style.overflow = "hidden";
      document.addEventListener("keydown", handleKeyDown);
      // Add focus
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    } else {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpenModal]);

  const handleIssuerSelect = (issuer) => {
    setCurrentIssuer(issuer);
    toggleModal();
  };

  return (
    <>
      <div
        className={`inline-flex relative backface-visible-hidden justify-start items-center gap-1 bg-black-dark hover:opacity-90 px-4 py-2 rounded-full text-white transition-all duration-[0.2s] cursor-pointer overflow-hidden ${
          isLogin ? "whitespace-nowrap" : ""
        }`}
        onClick={toggleModal}
      >
        <span className="relative">
          {currentIssuer.image === 'default-icon-day-v3.svg' ? (
            <Avatar
              name={currentIssuer.coin_name}
              size="24"
              round={true}
            />
          ) : (
            <img
              src={require(`../../../../../assets/images/issuer/${currentIssuer.image}`)}
              alt={currentIssuer.coin_name}
              className="rounded-full w-6 h-6"
            />
          )}

          <NetworkIcon network={currentIssuer.network} />
        </span>
        {currentIssuer.coin_name}
        <Icons nameIcon="chevron-down" className="w-4 h-4" />
      </div>

      <AnimatePresence>
        {isOpenModal && (
          <motion.div
            className="z-50 fixed inset-0 p-6 max-767:p-0 overflow-auto modal scroll-smooth"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div
              className="fixed inset-0 bg-black-light opacity-75 modal__overlay"
              onClick={toggleModal}
            />

            <motion.div
              className="relative top-1/2 z-10 bg-black-dark shadow-[transparent_0_0_0_0,transparent_0_0_0_0,#1e2025_0_0_0_1px_inset,#00000054_0_32px_64px_-16px] m-auto rounded-3xl w-full max-w-[380px] modal__wrapper"
              initial={{ opacity: 0, y: "calc(-50% + 16px)" }}
              animate={{ opacity: 1, y: "-50%" }}
              exit={{ opacity: 0, y: "calc(-50% + 16px)" }}
              transition={{ duration: 0.2 }}
            >
              <button
                className="inline-flex top-5 right-6 z-[2] absolute justify-center items-center bg-[#0000] shadow-[inset_0_0_0_2px_#fff] hover:shadow-[inset_0_0_0_2px_#6F737E] rounded-[50%] w-8 h-8 text-white hover:text-[#6F737E] transition-all duration-[0.2s] cursor-pointer"
                onClick={toggleModal}
              >
                <Icons nameIcon="close" className="w-4 h-4" />
              </button>

              {/* Modal header */}
              <div className="relative flex items-center gap-3 pt-6 pr-[62px] pb-4 pl-6">
                <div className="block m-0 pb-4 w-full text-left text-lg text-white break-words leading-6">
                  Select an issuer
                </div>
              </div>

              {/* Modal content */}
              <div className="border-0 m-0 px-6 max-767:px-6 pt-0 pb-6 align-baseline">
                <div className="border-0 m-0 mb-3 p-0 align-baseline">
                  <div className="relative border-0 m-0 p-0 text-[#6F737E] align-baseline">
                    <Icons
                      nameIcon="search"
                      className="inline-flex top-2/4 left-3 z-[2] absolute justify-center items-center w-6 h-6 -translate-y-3 pointer-events-none align-middle"
                    />
                    <input
                      ref={searchInputRef}
                      name="token_name"
                      type="text"
                      className="block border-[#72798a] border-2 bg-[initial] !py-0 !pr-4 !pl-12 border-solid rounded-xl w-full !h-11 font-medium font-sans !text-sm text-white leading-[1.71429] tracking-[-0.02em] transition-[border-color] duration-[0.2s] appearance-none outline-none placeholder-[#6F737E]"
                      placeholder={t('investment.issuer.search_placeholder')}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>

                <div className="border-0 -mx-6 my-0 px-6 py-0 max-h-80 overflow-y-auto align-baseline scroll-smooth scrollbar-custom">
                  <div className="w-full h-4" />
                  <div className="border-0 m-0 p-0 font-bold text-[#6F73E] text-[#777e90] text-xs uppercase align-baseline">
                    Issuers
                  </div>

                  {/* Issuer list */}
                  {isLoading ? (
                    <div className="justify-start items-start gap-x-3 gap-y-3 border-0 grid grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mx-auto my-0 px-0 pt-3 pb-6 align-baseline">
                      {[1, 2, 3, 4].map((index) => (
                        <IssuerSkeleton key={`issuer-skeleton-${index}`} />
                      ))}
                    </div>
                  ) : (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.3 }}
                      className="justify-start items-start gap-x-3 gap-y-3 border-0 grid grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mx-auto my-0 px-0 pt-3 pb-6 align-baseline"
                    >
                      {filteredIssuers.length === 0 ? (
                        <div className="py-2 text-center text-gray-lighter text-sm">
                          No issuers found
                        </div>
                      ) : (
                        filteredIssuers.map((issuer, index) => (
                          <Issuer
                            key={`issuer-${index}`}
                            imgSrc={issuer.image === 'default-icon-day-v3.svg' ? null : require(`../../../../../assets/images/issuer/${issuer.image}`)}
                            issuerName={`${issuer.coin_name} - ${issuer.company_name}`}
                            issuerText={issuer.text}
                            issuerNetwork={issuer.network}
                            coinName={issuer.coin_name}
                            onClick={() => handleIssuerSelect(issuer)}
                          />
                        ))
                      )}
                    </motion.div>
                  )}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default DropdownModal;
