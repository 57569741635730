// src/pages/DashboardTokens/AddWallet/TokenForm.jsx

import React, { useEffect, useRef, useState } from 'react'
import Loader from '../../../components/shared/Loader'
import GroupedTokenList from "./GroupedTokenList"
import Assets from './Assets'

const TokenForm = ({
  formData,
  handleChange,
  handlePriceChange,
  isLoading,
  tokens,
  isValid,
  onNext,
}) => {
  const inputRef = useRef(null)
  const [isWalletAddressFocus, setIsWalletAddressFocus] = useState(false)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const classFieldWrapper = "relative border-0 m-0 p-0 text-gray-lighter align-baseline"
  const classInputField = "w-full font-sans text-base leading-6 font-normal min-w-0 text-white text-start !p-4 bg-black-dark transition-all duration-[0.2s] ease-linear rounded-xl outline-none border-2 border-solid placeholder-[#6F737E] font-sans pr-8"

  return (
    <>
      <div className="flex-1 border-0 m-0 mb-3 p-0 w-full align-baseline">
        <div className={classFieldWrapper}>
          <span className="block mb-2 font-sans text-[13px] text-gray-lighter leading-[1.2] tracking-[-0.02em]">
            Wallet address
          </span>
        </div>

        <div className="relative">
          <input
            type="text"
            className={`${classInputField} ${isWalletAddressFocus ? 'border-[#72798a]' : 'border-white/20'}`}
            placeholder="Enter wallet address or ENS"
            onFocus={() => setIsWalletAddressFocus(true)}
            onBlur={() => setIsWalletAddressFocus(false)}
            value={formData.maskedAddress}
            onChange={(e) => handleChange('walletAddress')(e)}
            ref={inputRef}
          />
          {isLoading && (
            <div className="top-1/2 right-4 absolute -translate-y-1/2">
              <Loader className="relative -top-[6px]" />
            </div>
          )}
        </div>

        {formData.walletAddress && !isLoading && (
        <>
          <div className="w-full h-6" />
          <div className="-mx-6 px-6 max-h-96 scrollbar-custom">
            <GroupedTokenList
              tokensWithPrice={tokens.withPrice}
              tokensWithoutPrice={tokens.withoutPrice}
              onPriceChange={handlePriceChange}
              manualPrices={formData.manualPrices}
            />
          </div>
        </>
        )}
      </div>

      <div className="bottom bottom-0 py-6 w-full">
        <div className="flex justify-center items-center w-full">
          <button
            type="button"
            disabled={!isValid}
            className={`inline-flex justify-center items-center ${!isValid ? "bg-black-light text-white cursor-not-allowed" : "bg-primary text-white hover:bg-[#0148dd] hover:opacity-90"} m-0 mx-auto px-6 rounded-2xl w-full max-w-sm h-11 font-medium text-[100%] normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible`}
            onClick={onNext}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  )
}

export default TokenForm
