import { gql } from '@apollo/client';

export const CREATE_SPENDING_CAP = gql`
  mutation CreateSpendingCap($input: SpendingCapInput!) {
    createSpendingCap(input: $input) {
      id
      token
      network
      limit
      used
      issuer_ids
    }
  }
`;

export const UPDATE_SPENDING_CAP = gql`
  mutation UpdateSpendingCap($input: UpdateSpendingCapInput!) {
    updateSpendingCap(input: $input) {
      id
      token
      network
      limit
      used
      issuer_ids
    }
  }
`;

export const DELETE_SPENDING_CAP = gql`
  mutation DeleteSpendingCap($token: String!, $network: String!) {
    deleteSpendingCap(token: $token, network: $network)
  }
`;