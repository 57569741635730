import React, { useState } from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';
import Loader from '../../../../components/shared/Loader';

const StepTransitionButton = ({
  onSubmit,
  disabled,
  isLoading,
  children
}) => {
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    setIsTransitioning(true);
    await onSubmit();
    setIsTransitioning(false);
  };

  return (
    <motion.button
      type="submit"
      disabled={disabled || isTransitioning}
      onClick={handleClick}
      className={cn(
        "inline-flex justify-center items-center  m-0 px-6 !rounded-2xl w-full !h-14 font-semibold text-lg text-white normal-case leading-none transition-colors duration-[0.2s]  overflow-visible outline-none",
        {
          "bg-gray-custom/20 cursor-not-allowed": disabled || isTransitioning,
          "bg-primary hover:bg-primary-hover hover:opacity-90 cursor-pointer": !disabled && !isTransitioning
        }
      )}
      whileTap={{ scale: 0.98 }}
    >
      {(isTransitioning || isLoading) ? (
        <div className="flex items-center gap-2">
          <Loader size="small" className="relative top-[4px] mr-2 w-3 h-3" />
          <span>Processing...</span>
        </div>
      ) : children}
    </motion.button>
  );
};

export default StepTransitionButton;
