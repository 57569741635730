import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useTranslation } from 'react-i18next';
import { useFormContext } from "react-hook-form";
import { useQuery } from '@apollo/client';
import { GET_SPENDING_CAP } from '../../queriesAndMutations';

const motionConfig = {
  initial: { opacity: 0, y: 4 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -4 },
  transition: { duration: 0.2 }
};

const FetchSpendingCapInvestment = ({ value, currentIssuer }) => {
  const { t } = useTranslation();
  const { getValues, watch } = useFormContext();
  const currency = getValues('currency');

  const [spendingCapInfo, setSpendingCapInfo] = useState({
    total: '',
    remaining: ''
  });

  // Query spending cap
  const { data, loading: queryLoading, refetch } = useQuery(GET_SPENDING_CAP, {
    variables: {
      token: currency,
      network: currentIssuer.network === 'mainnet' || currentIssuer.network === 'mainnet-only' ? 'ethereum' : currentIssuer.network
    },
    skip: !currency || !currentIssuer.network
  });

  useEffect(() => {
    const subscription = watch(async (value, { name }) => {
      if (name === 'currency') {
        refetch({
          token: value.currency,
          network: currentIssuer.network === 'mainnet' || currentIssuer.network === 'mainnet-only' ? 'ethereum' : currentIssuer.network
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, refetch, currentIssuer]);

  useEffect(() => {
    if (!data || !currentIssuer) return;

    // Find the relevant spending cap for current token and network
    const spendingCap = data.getSpendingCap;

    if (spendingCap) {
      const remainingValue = +spendingCap.limit - (+spendingCap.used);
      setSpendingCapInfo({
        total: `${spendingCap.limit} ${spendingCap.token}`,
        remaining: remainingValue < 0 ? t('investment.spending-cap.maximum_reached') : `${remainingValue} ${spendingCap.token}`
      });
    } else {
      setSpendingCapInfo({
        total: t('investment.spending-cap.not_set'),
        remaining: t('investment.spending-cap.not_set')
      });
    }
  }, [data, currency, currentIssuer, value, t]);

  const SkeletonSpendingCap = () => (
    <div className="mt-[2px]">
      <Skeleton height={8} width="70%" className='!bg-gray-custom/25 opacity-25' />
    </div>
  );

  return (
    <div className="mt-[2px] pl-[2px] font-sans font-semibold text-[13px] text-gray-custom">
      <AnimatePresence mode="wait">
        {queryLoading ? (
          <motion.div
            key="skeleton"
            {...motionConfig}
          >
            <SkeletonSpendingCap />
          </motion.div>
        ) : (
          <motion.p
            key="content"
            {...motionConfig}
          >
            {t('investment.spending-cap.total')}: {spendingCapInfo.total}{'. '}
            {t('investment.spending-cap.remaining')}: {spendingCapInfo.remaining}
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FetchSpendingCapInvestment;
