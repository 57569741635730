/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
// src/pages/DashboardTokens/AddWallet/index.jsx

import React, { useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client';
import { motion, AnimatePresence } from "framer-motion"
import { NETWORK_IMAGES } from '../../../constant/images'
import Icons from '../../../components/shared/Icons'
import { useOverlay } from '../../../hooks/common/useOverlay'
import { modalOverlayVariants, modalContentVariants } from '../../../utils/animations'
import TokenForm from './TokenForm'
import Confirmation from "./Confirmation"
import Success from './Success'
import { toaster } from '../../../utils'
import {
  GET_TOKEN_BALANCES_BY_ADDRESS,
  CREATE_WALLET,
  BULK_CREATE_WALLET_TOKENS,
  GET_USER_WALLETS,
  SYNC_TRANSACTIONS
} from '../../../queriesAndMutations';
import tokenList from "../../../services/listDataToken"
import { ethers } from 'ethers'

export const DEFAULT_TOKEN_VALUES = {
  SYMBOL: 'ENC',
  DECIMAL: '9',
}

const ADD_NEW_TOKEN_STEPS = {
  CONTRACT_ADDRESS: 'token-contract-address',
  CONFIRMATION: 'confirmation-add',
  SUCCESS: 'success-add-token',
}

const AddWallet = () => {
  const { isOpen, open: openModal, close: closeModal } = useOverlay()
  const [step, setStep] = useState(ADD_NEW_TOKEN_STEPS.CONTRACT_ADDRESS)
  const [getTokenBalancesByAddress] = useLazyQuery(GET_TOKEN_BALANCES_BY_ADDRESS);
  const [createWallet] = useMutation(CREATE_WALLET);
  const [bulkCreateWalletTokens] = useMutation(BULK_CREATE_WALLET_TOKENS);
  const [syncTransactions] = useMutation(SYNC_TRANSACTIONS);

  const [isLoading, setIsLoading] = useState(false)

  const [tokens, setTokens] = useState({
    withPrice: [],
    withoutPrice: [],
  })

  const [walletData, setWalletData] = useState({
    address: '',
    tokens: [],
  })

  const [formData, setFormData] = useState({
    walletAddress: '',
    maskedAddress: '',
    manualPrices: {},
  })

  // Add debounce timeout ref
  const debounceTimeout = React.useRef(null);

  const getNetworkImage = (chainIndex) => {
    const networks = {
      '137': 'polygon.png',
      '8453': 'base.svg',
      '1': 'ethereum.png',
      '11155111': 'ethereum.png',
      '56': 'BNB.png',
      '43114': 'AVAX.png',
      '10': 'Optimism.png',
      '42161': 'arbitrum.png',
      '100': 'gnosis.png',
    };
    return networks[chainIndex] || '';
  };

  const getTokenImage = (tokenSymbol) => {
    const token = tokenList.find(token => token.symbol.toLowerCase() === tokenSymbol.toLowerCase());
    return token ? token.image : null;
  };

  const getImageUrl = (type, imageName) => {
    if (imageName) {
      return require(`../../../assets/images/${type}/${imageName}`);
    }
    return NETWORK_IMAGES.defaultToken
  }

  const handleCloseModal = (e) => {
    if (e) e.preventDefault()
    closeModal()
    // Reset states when modal closes
    setStep(ADD_NEW_TOKEN_STEPS.CONTRACT_ADDRESS)
    setFormData({
      walletAddress: '',
      manualPrices: {},
    })
    setTokens({
      withPrice: [],
      withoutPrice: [],
    })
  }

  const handlePrevStep = () => {
    if (step === ADD_NEW_TOKEN_STEPS.CONFIRMATION) {
      setStep(ADD_NEW_TOKEN_STEPS.CONTRACT_ADDRESS)
    }
  }

  const handleNextStep = async () => {
    switch (step) {
      case ADD_NEW_TOKEN_STEPS.CONTRACT_ADDRESS:
        setStep(ADD_NEW_TOKEN_STEPS.CONFIRMATION)
        break
      case ADD_NEW_TOKEN_STEPS.CONFIRMATION:
        try {
          setIsLoading(true)
          const { data: walletData } = await createWallet({
            variables: {
              wallet_address: formData.walletAddress,
              chain_index: "1",
              source: "manual" // or pass from props if connected via wallet
            }
          });

          if (walletData && walletData.createWallet) {
            // Only save tokens without price
            const tokensWithoutPrice = tokens.withoutPrice.map(token => ({
              symbol: token.symbol,
              contract_address: token.contractAddress,
              entry_price: parseFloat(formData.manualPrices[token.symbol] ? formData.manualPrices[token.symbol].entryPrice : 0),
              current_price: parseFloat(formData.manualPrices[token.symbol] ? formData.manualPrices[token.symbol].currentPrice : 0),
              quantity: parseFloat(token.quantity),
              chain_index: token.chainIndex
            }));

            // Create tokens without price for the wallet
            if (tokensWithoutPrice.length > 0) {
              await bulkCreateWalletTokens({
                variables: {
                  wallet_id: walletData.createWallet.id,
                  tokens: tokensWithoutPrice
                },
                refetchQueries: [{ query: GET_USER_WALLETS }]
              });
            }

            await syncTransactions({
              variables: {
                wallet_id: walletData.createWallet.id
              },
            });
          }
          setStep(ADD_NEW_TOKEN_STEPS.SUCCESS)
        } catch (error) {
          console.error('Error creating wallet:', error);
          toaster.error(error.message)
        } finally {
          setIsLoading(false)
        }
        break
      default:
        break
    }
  }

  // Update handleChange to use debounce
  const handleChange = (field) => async (e) => {
    const { value } = e.target

    let walletAddress = value;

    if (field === 'walletAddress') {
      // Update form data immediately for UI responsiveness
      setFormData((prev) => ({
        ...prev,
        maskedAddress: value,
        walletAddress: value,
      }))

      // Clear any existing timeout
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      // Only make API call if there's a value
      if (value) {
        setIsLoading(true);
        // Set new timeout for API call
        debounceTimeout.current = setTimeout(async () => {
          try {
            // check value not starts with 0x
            if (!value.startsWith('0x')) {
              const provider = new ethers.providers.CloudflareProvider()
              const address = await provider.resolveName(value)

              console.log(address)

              setFormData((prev) => ({
                ...prev,
                walletAddress: address,
              }))

              walletAddress = address
            }

            const { data } = await getTokenBalancesByAddress({
              variables: { address: walletAddress },
            });

            if (data && data.getTokenBalancesByAddress && data.getTokenBalancesByAddress.tokenAssets) {
              const convertTokens = data.getTokenBalancesByAddress.tokenAssets.map((token) => ({
                symbol: token.symbol,
                contractAddress: token.tokenAddress,
                quantity: token.balance,
                price: token.tokenPrice,
                change24h: 0,
                value: token.balance * token.tokenPrice,
                icon: getImageUrl('tokenSymbol', getTokenImage(token.symbol)),
                chainIndex: token.chainIndex,
                networkIcon: getImageUrl('tokenSymbol', getNetworkImage(token.chainIndex)),
              }));

              const withoutPriceTokens = convertTokens.filter((token) => +token.price === 0);
              const withPriceTokens = convertTokens.filter((token) => +token.price !== 0);

              setTokens({
                withPrice: withPriceTokens,
                withoutPrice: withoutPriceTokens,
              });
            }
          } catch (error) {
            console.error('Error fetching token balances:', error);
          } finally {
            setIsLoading(false);
          }
        }, 500); // 500ms delay
      } else {
        setIsLoading(false);
        setTokens({
          withPrice: [],
          withoutPrice: [],
        });
      }
    }
  }

  const isValid = () => {
    if (!formData.walletAddress) return false

    // // Check if all tokens without price have both entry and current price
    // const allPricesEntered = tokens.withoutPrice.every((token) => {
    //   const prices = formData.manualPrices[token.symbol] || {}
    //   return prices.entryPrice && prices.currentPrice
    // })

    return formData.walletAddress &&
            (tokens.withPrice.length > 0 ||
            (tokens.withoutPrice.length > 0))
  }

  const handlePriceChange = (tokenSymbol) => (priceData) => {
    setFormData((prev) => ({
      ...prev,
      manualPrices: {
        ...prev.manualPrices,
        [tokenSymbol]: priceData,
      },
    }))
  }

  return (
    <>
      <button
        type="button"
        className="inline-flex justify-center items-center bg-primary hover:bg-primary-hover hover:opacity-90 m-0 px-6 rounded-3xl h-10 font-medium font-sans text-[100%] text-white normal-case leading-none tracking-[-0.02em] transition-colors duration-[0.2s] cursor-pointer overflow-visible outline-none"
        onClick={openModal}
      >
        Add Wallet
      </button>

      <AnimatePresence>
        {isOpen && (
        <motion.div
          className="z-50 fixed inset-0 p-6 max-767:p-0 overflow-auto modal scroll-smooth"
          {...modalOverlayVariants}
        >
          <div
            className="fixed inset-0 bg-black-light opacity-75 cursor-pointer modal__overlay"
            onClick={handleCloseModal}
          />

          <motion.div
            className="relative top-1/2 z-10 bg-black-dark shadow-[transparent_0_0_0_0,transparent_0_0_0_0,#1e2025_0_0_0_1px_inset,#00000054_0_32px_64px_-16px] m-auto rounded-3xl w-full max-w-[380px] modal__wrapper"
            {...modalContentVariants}
          >

            {step !== 'success-add-token' && (
            <>
              <button
                type="button"
                className="inline-flex top-5 right-6 z-[2] absolute justify-center items-center bg-[#0000] shadow-[inset_0_0_0_2px_#fff] hover:shadow-[inset_0_0_0_2px_#6F737E] rounded-[50%] w-8 h-8 text-white hover:text-gray-lighter transition-all duration-[0.2s] cursor-pointer"
                onClick={handleCloseModal}
              >
                <Icons nameIcon="close" className="w-4 h-4" />
              </button>

              <div className="relative flex items-center gap-3 pt-6 pr-[62px] pb-4 pl-6">
                <div className="block m-0 pb-4 w-full font-sans text-left text-lg text-white break-words leading-6">
                  Add new wallet
                </div>
              </div>
            </>
            )}

            <div className="flex flex-col flex-nowrap justify-start items-start border-0 m-0 px-6 max-767:px-6 pt-0 pb-6 min-h-[420px] align-baseline">
              {step === 'token-contract-address' && (
              <TokenForm
                formData={formData}
                handleChange={handleChange}
                handlePriceChange={handlePriceChange}
                isLoading={isLoading}
                tokens={tokens}
                isValid={isValid()}
                onNext={handleNextStep}
              />
              )}

              {step === 'confirmation-add' && (
              <Confirmation
                formData={formData}
                tokens={tokens}
                onNext={handleNextStep}
                onPrev={handlePrevStep}
              />
              )}

              {step === 'success-add-token' && (
              <Success />
              )}

            </div>
          </motion.div>
        </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default AddWallet
