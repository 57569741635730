import { useState } from 'react';

const useViewMode = () => {
  const [viewMode, setViewMode] = useState('combined'); // combined | by-wallet

  const toggleViewMode = (mode) => {
    setViewMode(mode);
  };

  return {
    viewMode,
    toggleViewMode,
    isCombinedView: viewMode === 'combined',
    isByWalletView: viewMode === 'by-wallet'
  };
};

export default useViewMode
