import { gql } from '@apollo/client';

export const GET_USER_IP_WHITELIST = gql`
  query GetUserIpWhitelist {
    getUserIpWhitelist {
      id
      ip_address
      description
      is_active
      createdAt
      updatedAt
    }
  }
`;
