import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import TextField from './TextField';
import { GET_USER_IP_WHITELIST, ADD_IP_WHITELIST, REMOVE_IP_WHITELIST, UPDATE_USER } from '../../../../queriesAndMutations';
import { useMe } from '../../../../myHooks';
import { toaster } from '../../../../utils';

const IpWhitelist = () => {
  const { t } = useTranslation();
  const [newIp, setNewIp] = useState('');
  const { data: { me } = {}, loading: meLoading, refetch: meRefetch } = useMe({ fetchPolicy: 'network-only' })

  // Query to fetch existing IP whitelist
  const { data, loading, refetch } = useQuery(GET_USER_IP_WHITELIST);
  const ipAddresses = (data && data.getUserIpWhitelist) || [];

  // Mutations for adding and removing IPs
  const [addIpWhitelist] = useMutation(ADD_IP_WHITELIST);
  const [removeIpWhitelist] = useMutation(REMOVE_IP_WHITELIST);

  const addIpAddress = async (e) => {
    e.preventDefault();
    if (newIp && !ipAddresses.some(ip => ip.ip_address === newIp)) {
      try {
        await addIpWhitelist({
          variables: {
            ip_address: newIp
          }
        });
        setNewIp('');
        refetch(); // Refresh the list
      } catch (error) {
        console.error('Error adding IP:', error);
        // You might want to show an error message to the user
      }
    }
  };

  const removeIpAddress = async (ip) => {
    try {
      await removeIpWhitelist({
        variables: {
          id: ip.id
        }
      });
      refetch(); // Refresh the list
    } catch (error) {
      console.error('Error removing IP:', error);
      // You might want to show an error message to the user
    }
  };

  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: ['me']
  })

  const handleToggleIpWhitelist = async () => {
    try {
      await updateUser({
        variables: {
          input: {
            isIPWhitelistEnabled: !me.isIPWhitelistEnabled
          }
        }
      })
      meRefetch()
      toaster.success('IP whitelist updated successfully')
    } catch (error) {
      console.error('Error:', error)
      toaster.error(error.message)
    }
  }

  if (meLoading) return null

  return (
    <>
      <div className='relative flex flex-row flex-wrap justify-start items-center gap-1 mb-4 pb-4 border-b border-b-white/10 border-solid'>
        {/* <div className="flex flex-row flex-wrap flex-1 gap-1 font-sans font-semibold text-[13px] text-gray-lighter leading-[1.2] tracking-[-0.02em] transition-colors duration-[0.2s]">
          {t('settings.monerium.security.ip_whitelist')}
        </div> */}
        <div className="mb-6 font-sans font-semibold text-base text-start text-white leading-6 tracking-[-0.02em]">
          {t('settings.monerium.security.ip_whitelist')}
        </div>

        <label className="inline-block relative ml-auto cursor-pointer">
          <input
            type="checkbox"
            className="absolute opacity-0 w-0 h-0"
            onChange={handleToggleIpWhitelist}
            checked={me.isIPWhitelistEnabled}
          />
          <span className={`flex items-center justify-center transition-all duration-[0.2s] ${me.isIPWhitelistEnabled ? 'text-white' : 'text-gray-lighter'}`}>
            <span className={`block relative border-2 border-white/10 bg-[#1e2025] border-solid rounded-3xl w-12 h-6 transition-all duration-[0.2s] toggle-checkbox__box before:content-[""] before:absolute  before:w-5 before:h-5 before:transition-all before:duration-[0.2s] before:rounded-[50%] before:left-0.5 before:top-2/4 ${me.isIPWhitelistEnabled ? 'before:bg-primary before:translate-x-6 before:-translate-y-2/4' : 'before:bg-white before:-translate-y-2/4 '}`} />
          </span>
        </label>
      </div>
      <form onSubmit={addIpAddress} className="gap-5 max-767:gap-6 grid grid-cols-[1fr] max-767:grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mb-6">
        <TextField
          value={newIp}
          onChange={(e) => setNewIp(e.target.value)}
          placeholder={t('settings.monerium.security.ip_placeholder')}
          prefixAction={true}
          label={t('settings.monerium.security.ip_label')}
          items={ipAddresses.map(ip => ({ ...ip, value: ip.ip_address }))}
          onRemove={removeIpAddress}
          isLoading={loading}
        />
      </form>
    </>
  );
};

export default IpWhitelist;
