import React from "react";
import { motion, AnimatePresence } from 'framer-motion';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
// Inner
import { FieldBuy, FieldInvest } from "../../FieldBuyInvest";
import FetchSpendingCapInvestment from "../../../../components/FetchSpendingCapInvestment";

const motionConfig = {
  initial: { opacity: 0, y: 4 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -4 },
  transition: { duration: 0.2 }
};

const SkeletonLoading = () => (
  <div className='relative flex flex-row flex-wrap justify-start items-center gap-1 [&>span]:w-full'>
    <Skeleton width={100} height={117.18} className="!bg-gray-custom/25 opacity-25 !rounded-2xl !w-full" />
  </div>
)

const StepBuyAndInvest = ({
  isLogin,
  buyValue,
  investValue,
  handleInvestChange,
  setCurrentIssuer,
  currentIssuer,
  balanceAfterTopup,
  fetchingData,
  fetchingRateAndPrice,
}) => {
  return (
    <>
      <FieldInvest
        isLogin={isLogin}
        onChange={handleInvestChange}
        value={investValue}
        setCurrentIssuer={setCurrentIssuer}
        currentIssuer={currentIssuer}
      />
      <FetchSpendingCapInvestment value={investValue} currentIssuer={currentIssuer} />
      <div className="w-full h-2" />
      <AnimatePresence mode="wait">
        {fetchingData || fetchingRateAndPrice ? (
          <motion.div
            key="skeleton"
            {...motionConfig}
          >
            <SkeletonLoading />
          </motion.div>
        ): (
          <motion.div
            key="content"
            {...motionConfig}
          >
            <FieldBuy
              isLogin={isLogin}
              // onChange={handleInvestChange}
              value={buyValue}
              balanceAfterTopup={balanceAfterTopup}
              currentIssuer={currentIssuer}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="w-full h-4" />
    </>
  );
};

export default StepBuyAndInvest;
