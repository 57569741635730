import React, { useMemo, useState, useEffect } from 'react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import { format } from 'date-fns'
import ChartControls from './ChartControls'
import styles from './PortfolioOverview.module.scss'
import { useLazyQuery } from '@apollo/client'
import { CALCULATE_PROFIT_LOSS } from '../../../queriesAndMutations'
import { useMe } from '../../../myHooks'

const generateChartData = (timeRange) => {
  const now = new Date()
  const data = []
  let points
  let interval

  switch (timeRange) {
    case '1M':
      points = 30
      interval = 24 * 60 * 60 * 1000 // 1 day
      break

    case '6M':
      points = 180
      interval = 24 * 60 * 60 * 1000
      break

    case '12M':
      points = 365
      interval = 24 * 60 * 60 * 1000
      break

    case '24M':
      points = 730
      interval = 24 * 60 * 60 * 1000
      break

    default:
      points = 30
      interval = 24 * 60 * 60 * 1000
  }

  let baseValue = 91.78
  const volatility = 0.02 // 2% volatility

  for (let i = points; i >= 0; i -= 1) {
      const date = new Date(now.getTime() - (i * interval))
      const change = (Math.random() - 0.5) * volatility
      baseValue *= (1 + change)

      data.push({
          date: date,
          value: baseValue
      })
  }

  return data
}

const PortfolioOverview = ({
  timeRange = '1M',
  onTimeRangeChange,
  isTablet,
  isDesktopSmall,
  totalBalance
}) => {
  const data = useMemo(() => generateChartData(timeRange), [timeRange]);
  const [calculateProfitLoss] = useLazyQuery(CALCULATE_PROFIT_LOSS);
  const { data: { me: user } = {} } = useMe()
  const [loading, setLoading] = useState(false);

  const [profitLoss, setProfitLoss] = useState({});

  useEffect(() => {
    const fetchProfitLoss = async () => {
      setLoading(true);

      try {
        const result = await calculateProfitLoss({
          variables: {
            input: {
              timePeriod: timeRange,
            }
          }
        });

        setProfitLoss(result.data.calculateProfitLoss.profitLoss);
      } catch (error) {
        console.error('Error fetching profit loss:', error);
        setProfitLoss({});
      }
      setLoading(false);
    };

    fetchProfitLoss();
  }, [timeRange, calculateProfitLoss, user]);

  const formatXAxis = (tickItem) => {
    switch (timeRange) {
      case '1M':
        return format(new Date(tickItem), 'MMM d')

      case '6M':
      case '12M':
        return format(new Date(tickItem), 'MMM yyyy')

      case '24M':
        return format(new Date(tickItem), "MMM ''yy")

      default:
        return format(new Date(tickItem), 'MMM d')
    }
  }

  console.log('profitLoss', profitLoss);

  // calculate total profit loss
  const totalProfitLoss = Object.values(profitLoss)
    .flat()
    .reduce((total, tokenData) => total + parseFloat(tokenData.profitLoss.profitLoss), 0);

  return (
    <div className={styles.wrapper}>

      <div className="flex max-1279:flex-col justify-between max-1279:justify-start items-end max-1279:items-start max-1279:gap-6 mb-6 w-full">

        <div className="flex-1 grow">
          <div className="block mb-2 font-sans font-semibold text-[13px] text-gray-lighter tracking-[-0.02em]">
            Portfolio Overview
          </div>

          <div className="inline-flex items-end gap-2">
            <div className="font-bold font-sans text-5xl text-white max-1023:text-[2.5rem] leading-[3.5rem] max-1023:leading-[3rem] -tracking-wide max-1023:tracking-[-.045em]">
              ${totalBalance.toFixed(2)}
            </div>
            {loading && (
              <span className="pb-1 font-sans font-semibold text-white text-[13px] tracking-[-0.02em]">Calculating...</span>
            )}
            {!loading && (
              <span className={`pb-1 font-sans font-semibold text-[13px] text-${totalProfitLoss > 0 ? 'green' : 'red'}-500 tracking-[-0.02em]`}>{totalProfitLoss >= 0 ? '+' : '-'}${Math.abs(totalProfitLoss).toFixed(2)}</span>
            )}
          </div>
        </div>

        {/* Start time range controls */}
        <div className="max-1279:w-full">
          <ChartControls
            isDesktopSmall={isDesktopSmall}
            selectedRange={timeRange}
            onRangeChange={onTimeRangeChange}
          />
        </div>
        {/* End time range controls */}
      </div>
      {/* End chart header */}

      {/* Start chart area */}
      {/* <div className="-mx-6 max-767:-mx-4">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#0052FF" stopOpacity={0.2}/>
                <stop offset="95%" stopColor="#0052FF" stopOpacity={0}/>
              </linearGradient>
            </defs>

            {!isDesktopSmall && (
              <XAxis
                dataKey="date"
                tickFormatter={formatXAxis}
                tickLine={false}
                axisLine={false}
                minTickGap={30}
                padding={{ left: 0, right: 0 }}
                tick={{ fill: '#6F737E', fontSize: 12 }}
                interval="preserveStartEnd"
              />
            )}

            <YAxis hide={true} domain={['auto', 'auto']} />

            <Tooltip
              cursor={{
                stroke: '#2b2d33',
                strokeWidth: 1,
              }}
            />

            <Area
              type="monotone"
              dataKey="value"
              stroke="#0052FF"
              strokeWidth={2}
              fill="url(#colorValue)"
              fillOpacity={1}
              dot={false}
              activeDot={{
                r: 5,
                fill: '#0052FF',
                stroke: '#FFFFFF',
                strokeWidth: 2
              }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div> */}
      {/* End chart area */}
    </div>
  );
}

export default PortfolioOverview
