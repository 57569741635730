import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Icons from '../../../../components/shared/Icons';

const TextField = ({
  value,
  label,
  onChange,
  placeholder,
  prefixAction,
  error,
  items = [],
  onRemove,
  readonly
}) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative field__wrapper">
      {label && (
        <label
          className={cn(
            "flex flex-row flex-wrap gap-1 mb-3 text-[13px] leading-[1.2] tracking-[-0.02em] transition-colors duration-[0.2s] font-semibold font-sans",
            {
              "text-white": isFocused,
              "text-gray-lighter": !isFocused
            }
          )}
        >
          {label}
        </label>
      )}

      {!readonly && (
        <div className='relative'>
          <input
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={cn(
              "border-2 w-full border-solid text-base leading-6 font-normal min-w-0 text-white text-start bg-black-dark ease-linear rounded-xl outline-none p-4 transition-all duration-[0.2s] pr-24 font-sans",
              {
                "border-white/10": isFocused,
                "border-transparent": !isFocused
              }
            )}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />

          {prefixAction && (
            <button
              type="submit"
              className="inline-flex top-1/2 right-2 absolute justify-center items-center hover:bg-gray-custom/20 ml-2 max-767:ml-0 p-[6px] rounded-2xl transition-all -translate-y-1/2 duration-200"
            >
              <Icons
                nameIcon='plus'
                className="w-5 h-5 text-gray-custom"
              />
            </button>
          )}
        </div>
      )}

      {items.length > 0 && (
        <div className="space-y-2 mt-4">
          {items.map(item => (
            <div
              key={item.id}
              className="relative flex justify-start items-center border-2 border-white/10 bg-black-dark py-0 p-4 pr-24 border-solid rounded-xl w-full min-w-0 h-[3.25rem] font-normal font-sans text-base text-start text-white leading-6 transition-all duration-[0.2s] ease-linear outline-none"
            >
              <span className="text-white leading-none">{item.ip_address}</span>
              <button
                onClick={() => onRemove(item)}
                className="inline-flex top-1/2 right-2 absolute justify-center items-center hover:bg-red-500/20 ml-2 max-767:ml-0 p-[6px] rounded-2xl text-red-500 transition-all -translate-y-1/2 duration-200"
              >
                <Icons nameIcon="trash" className="w-5 h-5" />
              </button>
            </div>
          ))}
        </div>
      )}

      {error && <span className="block relative pt-1 text-[13px] text-red-200 tracking-[-0.02em]">{error.message}</span>}
    </div>
  );
};

export default TextField;
