import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import { motion, AnimatePresence } from "framer-motion"
import Icons from "../../Icons"

const ModalWide = ({
  isOpen,
  onClose,
  title,
  subtitle,
  customClassModal,
  headerTitleLeft,
  hideCloseButton,
  hideHeader = false,
  highIndex = false,
  disableOverlayClick = false,
  isMaxHeight = false,
  children,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)

  useEffect(() => {
    setIsOpenModal(isOpen)
  }, [isOpen])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose()
      }
    }

    if (isOpenModal) {
      document.body.style.overflow = "hidden"
      document.addEventListener("keydown", handleKeyDown)
    } else {
      document.body.style.overflow = "auto"
      document.removeEventListener("keydown", handleKeyDown)
    }

    return () => {
      document.body.style.overflow = "auto"
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [isOpenModal, onClose])

  const modalRoot = document.getElementById("modal-root")
  if (!modalRoot) return null

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpen && (
        <div className={`z-50 fixed inset-0 flex p-6 max-767:p-0 transition-all duration-[0.2s] overflow-auto modal scroll-smooth ${highIndex ? 'z-[9999]' : 'z-50'}`}>
          <motion.div
            className={`fixed inset-0 bg-black-light opacity-75 modal__overlay ${!disableOverlayClick ? 'cursor-pointer' : ''}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.75 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            onClick={!disableOverlayClick ? onClose : undefined}
          />

          <motion.div
            className={`relative z-10 bg-black-dark shadow-[transparent_0_0_0_0,transparent_0_0_0_0,#1e2025_0_0_0_1px_inset,#00000054_0_32px_64px_-16px] m-auto rounded-3xl w-full max-w-[578px] modal__wrapper ${customClassModal} ${isMaxHeight ? 'max-h-[460px] scrollbar-custom overflow-y-auto overflow-x-hidden' : ''}`}
            initial={{ opacity: 0, translateY: 16 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: 16 }}
            transition={{ duration: 0.3 }}
          >
            {!hideCloseButton && (
              <button
                type="button"
                className="inline-flex top-5 right-6 z-[2] absolute justify-center items-center bg-[#0000] shadow-[inset_0_0_0_2px_#fff] hover:shadow-[inset_0_0_0_2px_#6F737E] rounded-[50%] w-8 h-8 text-white hover:text-gray-lighter transition-all duration-[0.2s] cursor-pointer"
                onClick={onClose}
              >
                <Icons nameIcon="close" className="w-4 h-4" />
              </button>
            )}

            {!hideHeader && (
              <div className={`relative flex flex-row flex-wrap  border-0 m-0 px-6 pb-4  align-baseline ${headerTitleLeft ? 'justify-start items-start text-left pt-6' : 'justify-center items-center text-center pt-[54px]'}`}>
                {title && <div className="block m-0 pb-4 w-full font-sans text-lg text-white break-words leading-6 tracking-[-0.02em]">{title}</div>}

                {subtitle && <div className="font-sans text-[13px] text-gray-lighter tracking-[-0.02em]">{subtitle}</div>}
              </div>
            )}

            {children && <div className="border-0 m-0 px-6 max-767:px-6 pt-4 pb-6 text-center align-baseline">{children}</div>}
          </motion.div>
        </div>
      )}
    </AnimatePresence>,
    document.getElementById("modal-root"),
  )
}

export default ModalWide
