import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMe } from '../../../myHooks';
import { toaster } from '../../../utils';
import { providers } from 'ethers';
import { constants } from '@monerium/sdk';
import WalletCard from './WalletCard';
// eslint-disable-next-line import/no-unresolved
import { useAppKitAccount, useAppKitProvider } from '@reown/appkit/react';
import { ethereum, walletClient } from '../../../myHooks/useCoinbaseAuth';
import metamaskService from '../../../services/metamask';

const { LINK_MESSAGE } = constants;

const WalletGroup = ({
  currency,
  monerium,
  profile,
  addresses,
  balances,
  ibans,
  setIbans,
  onRefreshBalances
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { walletProvider } = useAppKitProvider('eip155');
  const { address: wcAddress, isConnected: isWcConnected } = useAppKitAccount();
  const { data: { me: user } = {} } = useMe();

  console.log('balances', balances)

  // Transform addresses into wallet format with balances
  const wallets = addresses.map(address => {
    const addressIbans = ibans.filter(iban =>
      iban.address.toLowerCase() === address.address.toLowerCase() && iban.iban
    );

    const chainMapping = {
      ethereum: 'ethereum',
      sepolia: 'ethereum',
      polygon: 'polygon',
      amoy: 'polygon',
      base: 'base',
      baseSepolia: 'base',
    };

    return {
      address: address.address,
      // score: 95, // Mock score for now
      networks: [...(new Set(address.chains))].map(chain => ({
        name: chain,
        icon: chainMapping[chain] || 'unknown',
        balance: ((balances[address.address] || {})[chain] || {})[currency.toLowerCase()].balances[0].amount || 0,
        currency: currency,
        hasIban: addressIbans.some(iban => iban.chain === chain),
        chain: chain,
        iban: (addressIbans.find(iban => iban.chain === chain) || {}).iban,
        canMoveIban: currency === 'EUR' // Only allow IBAN movement for EUR
      }))
    };
  });

  const linkWallet = async (targetNetwork) => {
    try {
      setIsLoading(true);
      let signature;

      if (user.login_provider === 'coinbase') {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0xaa36a7' }], // Sepolia
        });

        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        signature = await walletClient.signMessage({
          account: accounts[0],
          message: LINK_MESSAGE,
        });
      } else if (user.login_provider === 'walletconnect' && isWcConnected) {
        const ethersProvider = new providers.Web3Provider(walletProvider);
        const hexMessage = `0x${Buffer.from(LINK_MESSAGE, "utf8").toString("hex")}`;

        signature = await ethersProvider.provider.request({
          method: 'personal_sign',
          params: [hexMessage, wcAddress]
        });
      } else {
        if (metamaskService.isMetamaskNotAvailable()) {
          toaster.error('You need to install Metamask to use this feature');
          return false;
        }
        signature = await metamaskService.signMessage(user.wallet_address, LINK_MESSAGE);
      }

      await monerium.linkAddress({
        profile: profile.id,
        address: user.wallet_address,
        message: LINK_MESSAGE,
        signature,
        chain: targetNetwork,
      });

      toaster.success('Wallet linked successfully');
      await onRefreshBalances(profile);
      setIsLoading(false);
      return true;
    } catch (err) {
      console.error('Error linking wallet:', err);
      toaster.error(`Error linking wallet: ${err.message}`);
      setIsLoading(false);
      return false;
    }
  };

  const moveIban = async (address, targetNetwork) => {
    // Only allow IBAN operations for EUR currency
    if (currency !== 'EUR') {
      return;
    }

    try {
      setIsLoading(true);

      const iban = ibans.filter(iban => iban.iban)[0];

      if (!iban) {
        toaster.error('No IBAN found');
        return;
      }

      const linkedAddress = addresses.find(addr =>
        addr.address.toLowerCase() === address.toLowerCase() &&
        addr.chains.includes(targetNetwork)
      );

      if (!linkedAddress) {
        toaster.error('Wallet not linked');
        return;
      }

      const result = await monerium.moveIban(iban.iban, {
        address: address,
        chain: targetNetwork,
      });

      if (result.status === 'Accepted') {
        toaster.success('IBAN moved successfully');
        const { ibans: newIbans } = await monerium.getIbans({
          profile: profile.id
        });
        setIbans(newIbans);
      }

      setIsLoading(false);
    } catch (err) {
      console.error('Error moving IBAN:', err);
      toaster.error(`Error moving IBAN: ${err.message}`);
      setIsLoading(false);
    }
  };

  const totalBalance = wallets.reduce((total, wallet) =>
    total + wallet.networks.reduce((sum, network) => sum + +network.balance, 0), 0
  );

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center bg-black-dark p-4 rounded-xl">
        <span className="font-sans font-semibold text-white">{currency}</span>
        <span className="font-sans text-white">
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          }).format(totalBalance)}
        </span>
      </div>

      {wallets.map(wallet => (
        <WalletCard
          key={wallet.address}
          wallet={wallet}
          onMoveIban={moveIban}
          isLoading={isLoading}
          showIban={currency === 'EUR'}
        />
      ))}
    </div>
  );
};

export default WalletGroup;
