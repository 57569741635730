import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icons from '../../../components/shared/Icons';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { toaster } from '../../../utils';

const WalletCard = ({ wallet, onMoveIban, isLoading, showIban }) => {
  const { t } = useTranslation();
  const [hoveredNetwork, setHoveredNetwork] = useState(null);

  const handleMoveIban = (network) => {
    onMoveIban(wallet.address, network);
  };

  const handleCopy = (text) => {
    if (!text) return

    navigator.clipboard.writeText(text).then(() => {
      toaster.info('Copied to clipboard')
    })
  }

  return (
    <div className="space-y-4 bg-black-dark p-4 rounded-xl">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <span className="font-mono text-white">{wallet.address}</span>
          <button
            className="hover:bg-white/5 p-1 rounded-md"
            onClick={() => handleCopy(wallet.address)}
          >
            <Icons nameIcon="copy" className="w-4 h-4 text-gray-lighter" />
          </button>
        </div>
        <div className="flex items-center gap-2">
          <Icons nameIcon="bar-chart" className="w-4 h-4 text-gray-lighter" />
          {/* <span className="font-medium font-sans text-white">Score: {wallet.score}</span> */}
        </div>
      </div>

      <div className="space-y-2">
        {wallet.networks.map((network) => (
          <div
            key={network.name}
            className="flex justify-between items-center bg-black p-3 rounded-lg"
            onMouseEnter={() => setHoveredNetwork(network.name)}
            onMouseLeave={() => setHoveredNetwork(null)}
          >
            <div className="flex items-center gap-3">
              <Icons nameIcon={network.icon} className="w-6 h-6" />
              <span className="font-sans text-white">{network.name}</span>
            </div>

            <div className="flex items-center gap-4">
              {showIban && hoveredNetwork === network.name && !network.hasIban && (
                <button
                  className="font-sans text-primary text-sm hover:text-primary/80"
                  onClick={() => handleMoveIban(network.name)}
                  disabled={isLoading}
                >
                  Move IBAN
                </button>
              )}
              {showIban && network.hasIban && (
                <button
                  className="font-sans text-primary text-sm text-primary/80"
                  onClick={() => handleCopy(network.iban)}
                  disabled={isLoading}
                >
                  {network.iban}
                </button>
              )}
              <span className="font-sans text-white">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: network.currency,
                }).format(network.balance)}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default WalletCard
