import React, { useState, useCallback } from 'react'
import Avatar from "react-avatar"

import ShortToken from '../../../components/shared/ShortToken'
import Icons from '../../../components/shared/Icons'

import SelectionCheckbox from './SelectionCheckbox'
import BaseButton from './BaseButton'
import ActionDropdown from './ActionDropdown'

const WalletRow = ({ wallet, isSelected, onSelect, rowData, openCollapseId, setOpenCollapseId, globalDropdownId, setGlobalDropdownId, refetchWallets }) => {
  const [buttonRect, setButtonRect] = useState(null)
  const isDropdownOpen = globalDropdownId === rowData.id

  const handleActionClick = useCallback((e) => {
    e.stopPropagation()

    const rect = e.currentTarget.getBoundingClientRect()

    if (openCollapseId) {
      Promise.resolve().then(() => {
        setButtonRect(rect)
        setOpenCollapseId(null)
        setGlobalDropdownId(rowData.id)
      })
      return
    }

    if (isDropdownOpen) {
      setGlobalDropdownId(null)
      setButtonRect(null)
    } else {
      setButtonRect(rect)
      setGlobalDropdownId(rowData.id)
    }
  }, [isDropdownOpen, rowData.id, setGlobalDropdownId, openCollapseId, setOpenCollapseId])

  return (
    <tr className="hover:bg-black-light/50 transition-colors">
      {/* <td className='py-4 align-middle' style={{ width: "40px" }}>
        <SelectionCheckbox
          checked={isSelected}
          onChange={() => onSelect(wallet.id)}
        />
      </td> */}

      <td className='py-4 font-medium font-sans'>
        <div className="flex items-center gap-4 w-full min-w-0">
          <Avatar
            name={wallet.name}
            rounded={true}
            size='40'
            className="flex-shrink-0 rounded-full"
          />

          <div className="flex flex-col flex-wrap flex-[1_1_0] justify-start items-start border-0 m-0 p-0 pt-[2px] font-semibold text-white align-baseline">
            <span className="flex-1 font-medium font-sans text-white">
              <ShortToken original={wallet.originalAddress} hasCopy={true} />
            </span>

            <span className="inline-flex justify-start items-center mt-1 font-semibold text-[13px] text-gray-custom text-style-1lines">
              <Icons nameIcon={wallet.icon} className="inline-flex mr-2 rounded-full w-5 h-5" />
              <span className='relative top-[2px]'>{wallet.provider}</span>
            </span>

          </div>
        </div>
      </td>

      <td className='py-4 text-center'>
        <span className="font-bold font-sans text-white tracking-[-0.02em]">{wallet.balance}</span>
      </td>

      <td className='py-4 text-center'>
        <BaseButton onClick={handleActionClick} className="bg-black-dark hover:bg-black-dark/20 rounded-full w-[36px] h-[36px] text-sm">
          <Icons nameIcon="dots" className="inline-flex w-6 h-6" />
        </BaseButton>

        {isDropdownOpen && buttonRect && (
          <ActionDropdown
            buttonRect={buttonRect}
            isOpen={isDropdownOpen}
            setOpenDropdownId={setGlobalDropdownId}
            rowData={rowData}
            refetchWallets={refetchWallets}
          />
        )}
      </td>
    </tr>
  )
}

export default WalletRow
