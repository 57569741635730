import { useState, useCallback } from 'react';
import Web3 from 'web3';
import { useMe } from './useMe';
import metamaskService from '../services/metamask';
import { COIN_DECIMALS, getCoinAddress, useWeb3Provider } from './useWeb3Provider';
import issuers from '../services/dummyDataIssuers';
import { useCoinbaseTrans } from '../pages/InstantInvestment/Steps/StepReviewProcessOrder/Processing/useCoinbaseTrans';

export const useTokenAllowance = (token, network) => {
  const [isApproving, setIsApproving] = useState(false);
  const [isApproveContract, setIsApproveContract] = useState(false);
  const { data: { me: user } = {} } = useMe();
  const { provider } = useWeb3Provider(network);
  const { handleApprove } = useCoinbaseTrans();

  const getCoinContract = useCallback((web3, coinAddress) => {
    return new web3.eth.Contract([{
      constant: true,
      inputs: [
        { name: 'owner', type: 'address' },
        { name: 'spender', type: 'address' }
      ],
      name: 'allowance',
      outputs: [
        { name: '', type: 'uint256' }
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    }, {
      constant: false,
      inputs: [
        { name: 'spender', type: 'address' },
        { name: 'value', type: 'uint256' }
      ],
      name: 'approve',
      outputs: [
        { name: '', type: 'bool' }
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    }], coinAddress);
  }, []);

  const checkAllowance = useCallback(async (amount, issuerIds, used = 0) => {
    console.log('checkAllowance', amount, issuerIds)
    if (!user || !user.wallet_address || !token || !network || !issuerIds || !issuerIds.length) return [];
    console.log('1111')

    if (!provider) {
      throw new Error('No provider available');
    }
    console.log('222')
    console.log('token', token)
    const web3 = new Web3(provider);
    const coinAddress = getCoinAddress(token.toLowerCase(), network.toLowerCase());

    if (!coinAddress) return [];
    console.log('3333')
    const contract = getCoinContract(web3, coinAddress);
    console.log('4444')

    try {
      // Check allowance for each issuer's paymentHub
      const allowanceChecks = await Promise.all(
        issuerIds.map(async (issuerId) => {
          const issuer = issuers.find(i => i.id === issuerId);
          if (!issuer || !issuer.paymentHub) {
            return {
              issuerId,
              approved: false
            };
          }

          console.log('5555')
          const allowance = await contract.methods
            .allowance(user.wallet_address, metamaskService.formatAddress(issuer.paymentHub))
            .call();

          console.log('6666')

          const currentAllowance = +(allowance.toString()) / (10 ** COIN_DECIMALS[token.toLowerCase()]);
          console.log('7777')

          console.log('currentAllowance', currentAllowance)
          console.log('amount', amount)
          return {
            issuerId,
            approved: currentAllowance >= amount - used
          };
        })
      );

      console.log('allowanceChecks', allowanceChecks)
      console.log('8888')
      return allowanceChecks;
    } catch (error) {
      console.error('Error checking allowance:', error);
      return [];
    }
  }, [user, token, network, getCoinContract, provider]);

  const approveToken = useCallback(async (amount, issuerIds) => {
    if (!user || !user.wallet_address || !token || !network || !issuerIds || !issuerIds.length) {
      throw new Error('Missing required parameters');
    }

    setIsApproving(true);
    try {
      if (!provider) {
        throw new Error('No provider available');
      }
      console.log('approveToken amount', amount)
      console.log('approveToken token', token)

      const approveAmount = Math.ceil(amount * (10 ** COIN_DECIMALS[token.toLowerCase()]))

      console.log('approveToken approveAmount', approveAmount)

      if (user.login_provider === 'coinbase') {
        const result = await Promise.all(
          issuerIds.map(async (issuerId) => {
            const issuer = issuers.find(i => i.id === issuerId);
            if (!issuer || !issuer.paymentHub) {
              throw new Error(`No payment hub found for issuer ${issuerId}`);
            }

            const approveResult = await handleApprove(approveAmount, token.toLowerCase(), issuer);
            return approveResult;
          })
        );

        console.log('result', result);

        setIsApproveContract(true);
        return result.every(r => r);
      }

      const web3 = new Web3(provider);
      const coinAddress = getCoinAddress(token.toLowerCase(), network.toLowerCase());

      if (!coinAddress) {
        throw new Error('Invalid token or network');
      }

      const contract = getCoinContract(web3, coinAddress);

      // Approve each issuer's paymentHub
      const result = await Promise.all(
        issuerIds.map(async (issuerId) => {
          const issuer = issuers.find(i => i.id === issuerId);
          if (!issuer || !issuer.paymentHub) {
            throw new Error(`No payment hub found for issuer ${issuerId}`);
          }

          const approveResult = await contract.methods
            .approve(metamaskService.formatAddress(issuer.paymentHub), BigInt(approveAmount).toString())
            .send({
              from: user.wallet_address
            });

          console.log('approveResult', approveResult);

          return approveResult;
        })
      );

      console.log('result', result);

      setIsApproveContract(true);
      return result.every(r => r);
    } catch (error) {
      console.error('Error approving token:', error);
      throw error;
    } finally {
      setIsApproving(false);
    }
  }, [user, token, network, getCoinContract, provider, handleApprove]);

  return {
    checkAllowance,
    approveToken,
    isApproving,
    isApproveContract
  };
};

export default useTokenAllowance;
