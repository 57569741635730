/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { useState } from 'react';
import { ethers } from 'ethers';
import { COIN_DECIMALS, getCoinAddress } from './useWeb3Provider';
import { Pool } from '@uniswap/v3-sdk';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Token, CurrencyAmount } from '@uniswap/sdk-core';
// eslint-disable-next-line import/no-extraneous-dependencies
import IUniswapV3PoolABI from '@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json';

const useFeeSwap = (provider, network = 'base') => {
  const [status, setStatus] = useState('');

  const feeRouterAddress = '0xB2c70ecC4ec062477C43EFB45522a07de9703442';
  const uniswapV3Factory = '0x33128a8fC17869897dcE68Ed026d694621f6FDfD';
  const feeRouterABI = [
    "function swapWithFee(address tokenIn, address tokenOut, uint256 amountIn, uint256 amountOutMin, uint24 fee, address recipient, uint256 deadline) external"
  ];

  const factoryABI = [
    "function getPool(address tokenIn, address tokenOut, uint24 fee) view returns (address)",
  ];

  const handleSwap = async (tokenIn, tokenOut, amountIn, recipient) => {
    if (!tokenIn || !tokenOut || !amountIn || !recipient) {
      setStatus('Please fill in all fields');

      return null;
    }

    console.log('provider', provider);
    console.log('network', network);

    console.log('tokenIn', tokenIn)
    console.log('tokenOut', tokenOut)
    console.log('amountIn', amountIn)
    console.log('recipient', recipient)

    try {
      // Initialize ethers.js provider and signer
      const ethersProvider = new ethers.providers.Web3Provider(provider);
      await ethersProvider.send('eth_requestAccounts', []); // Request account access
      const signer = ethersProvider.getSigner();

      // Connect to fee router contract
      const feeRouterContract = new ethers.Contract(feeRouterAddress, feeRouterABI, signer);

      const tokenInAddress = getCoinAddress(tokenIn, network);
      const tokenOutAddress = getCoinAddress(tokenOut, network);

      const feeTiers = [500, 3000, 10000]; // Check different fee tiers
      let selectedFeeTier = 0;

      const factoryContract = new ethers.Contract(uniswapV3Factory, factoryABI, signer);

      // Check for liquidity in each fee tier
      for (const fee of feeTiers) {
        const poolAddress = await factoryContract.getPool(tokenInAddress, tokenOutAddress, fee);

        console.log('poolAddress', poolAddress);

        const poolContract = new ethers.Contract(poolAddress, IUniswapV3PoolABI.abi, signer);
        const liquidity = await poolContract.liquidity();

        if (liquidity.gt(0)) {
          selectedFeeTier = fee;
          break;
        }
      }

      if (selectedFeeTier === 0) {
        setStatus('No liquidity available for this token pair.');

        return null;
      }

      // Approve token transfer to the contract
      const tokenContract = new ethers.Contract(tokenInAddress, [
        "function decimals() view returns (uint8)",
        "function approve(address spender, uint256 amount) public returns (bool)",
        "function allowance(address owner, address spender) public view returns (uint256)",
        "function balanceOf(address account) public view returns (uint256)"
      ], signer);

      const amountInWei = ethers.utils.parseUnits(amountIn.toString(), COIN_DECIMALS[tokenIn]); // Adjust decimals as needed
      // await tokenContract.approve(feeRouterAddress, amountInWei);
      setStatus('Approval successful, proceeding with swap...');

      const currentAllowance = await tokenContract.allowance(await signer.getAddress(), feeRouterAddress);
      const balance = await tokenContract.balanceOf(await signer.getAddress());

      if (balance.lt(amountInWei)) {
        throw new Error('Insufficient token balance.');
      }

      if (currentAllowance.lt(amountInWei)) {
        await tokenContract.approve(feeRouterAddress, amountInWei);
      }

      console.log('selectedFeeTier', selectedFeeTier)

      const url = `https://api.uniswap.org/v1/quote?protocol=V3&tokenInAddress=${tokenInAddress}&tokenInChainId=${8453}&tokenOutAddress=${tokenOutAddress}&tokenOutChainId=${8453}&type=exactIn&amount=${amountInWei.toString()}`;
      const response = await fetch(url);

      const quoteData = await response.json();

      console.log('quoteData', quoteData)

      if (!quoteData.quote) {
        throw new Error('No liquidity available for this token pair.');
      }

      // Set swap parameters
      const feeTier = selectedFeeTier; // 0.3% Uniswap V3 fee tier
      const amountOutMin = 0; // No minimum for demo purposes (adjust in production)
      const deadline = Math.floor(Date.now() / 1000) + 300; // 5-minute deadline

      console.log('amountInWei', amountInWei.toString())
      console.log('tokenInAddress', tokenInAddress)
      console.log('tokenOutAddress', tokenOutAddress)
      console.log('recipient', recipient)

      // Execute the swap
      const tx = await feeRouterContract.swapWithFee(
        tokenInAddress,
        tokenOutAddress,
        amountInWei.toString(),
        amountOutMin.toString(),
        feeTier,
        recipient,
        deadline.toString(),
      );

      setStatus(`Transaction sent: ${tx.hash}`);
      await tx.wait();
      setStatus('Swap completed successfully!');

      return tx.hash;
    } catch (error) {
      console.error('Error during swap:', error);
      setStatus(`Swap failed: ${error.message}`);

      return null;
    }
  };

  return { status, handleSwap };
};

export default useFeeSwap;
