import React, { useEffect, useState } from 'react'
import { ModalWide } from '../shared/Modals'
import InvesthubLogo from '../shared/InvesthubLogo'

const LegalDisclaimer = () => {
  const [showDisclaimer, setShowDisclaimer] = useState(false)

  useEffect(() => {
    const hasAccepted = localStorage.getItem('legal_disclaimer_accepted')
    if (!hasAccepted) {
      setShowDisclaimer(true)
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  const handleAccept = () => {
    localStorage.setItem('legal_disclaimer_accepted', 'true')
    setShowDisclaimer(false)
    document.body.style.overflow = 'auto'
  }

  return (
    <ModalWide
      isOpen={showDisclaimer}
      onClose={() => {}}
      hideCloseButton
      hideHeader
      highIndex
      isMaxHeight
      disableOverlayClick
    >
      <div className="pt-4 font-sans text-left">
        <InvesthubLogo className="relative mb-6 -ml-4 w-full max-w-[200px] h-auto"/>

        <p className="mb-4 font-semibold text-white">
          Disclaimer
        </p>

        <ol className="mb-4 pl-5 text-white list-decimal">
          <li className="mb-4">
            <div className="mb-1">
              <b>Service Description</b>
            </div>
            <p className="">
              This <b>User Interface ("UI")</b> provides a technical means for accessing certain decentralized finance ("<b>DeFi</b>") protocols and on-chain data. It displays publicly available blockchain information and may offer tools to interact with third-party protocols such as Balancer, Chainlink, Across, Uniswap, Spark, COWswap, and others (collectively, "<b>Third-Party Protocols</b>").
            </p>
          </li>

          <li className="mb-4">
            <div className="mb-1">
              <b>No Control Over Underlying Protocols</b>
            </div>
            <p>The protocols and smart contracts you access through the UI are not controlled, audited, maintained, or operated by <b>Investhub Genossenschaft LVC</b> (the “<b>UI Operator</b>”). All interactions you initiate via the UI are carried out at your own risk. The UI Operator assumes no liability for any losses or damages resulting from use of or reliance on these protocols. The UI does not verify the authenticity of publicly available token or protocol information displayed.</p>
          </li>

          <li className="mb-4">
            <div className="mb-1">
              <b>User Eligibility and Representation</b>
            </div>
            <p>By accessing this UI, you represent and warrant that:</p>
            <ul className='mb-4 pl-5 list-disc'>
              <li className='mb-4'>
                <p>You are not located in, under the control of, or a citizen of any jurisdiction that prohibits or restricts your use of the UI, including any “Prohibited Localities” as defined in the Terms of Service.</p>
              </li>

              <li className="mb-4">
                <p>You will not access this UI from within the United States or any Prohibited Locality.</p>
              </li>

              <li className="mb-4">
                <p>You are not using, nor will you use in the future, a VPN or other method to mask your physical location from a prohibited territory.</p>
              </li>

              <li className="mb-4">
                <p>You are lawfully permitted to access this UI and use its services under the laws of the jurisdiction where you reside and are located.</p>
              </li>
            </ul>
          </li>

          <li className="mb-4">
            <div className="mb-1">
              <b>Accuracy of Information</b>
            </div>
            <p>The UI sources data from blockchain systems and Third-Party Protocols. The UI Operator cannot guarantee the accuracy, completeness, or timeliness of this information. Asset values or other displayed protocol data may be subject to underlying protocol mechanics, governance changes, hacks, bugs, or malfunctions beyond the UI Operator’s control.</p>
          </li>

          <li className="mb-4">
            <div className="mb-1">
              <b>Risks of DeFi</b>
            </div>
            <p>DeFi protocols involve significant risk, including the potential total loss of digital assets due to exploits, attacks, protocol changes, or other unforeseen issues. Any interactions you undertake with these protocols are solely at your discretion. You bear full responsibility for assessing and understanding these risks.</p>
          </li>

          <li className="mb-4">
            <div className="mb-1">
              <b>No Recourse & Limited Liability</b>
            </div>
            <p>The UI and any interactions it enables operate autonomously and are outside the UI Operator’s direct control. If errors or failures occur within any smart contract, there is no recourse against the UI Operator. The UI Operator does not hold nor can it access user deposits or transactions. The UI Operator’s sole role is providing and maintaining the UI.</p>
          </li>

          <li className='mb-4'>
            <div className="mb-1">
              <b>Acceptance of Terms</b>
            </div>
            <p>By using this UI, you acknowledge these risks and agree to hold the UI Operator harmless from any losses, damages, or claims arising from your use of or reliance on the UI, Third-Party Protocols, or blockchain data. For further details, please refer to the Terms of Service for the UI.</p>
          </li>
        </ol>

        <p className="mb-4 font-semibold text-white">
          By accessing this site, you confirm that:
        </p>

        <p className="mb-4 text-white">
          You understand and accept the inherent risks of engaging with DeFi protocols, including but not limited to potential loss of some or all of your assets.
        </p>

        <p className="mb-6 text-white">DeFi activities involve significant risk and are highly experimental. Proceed with caution and conduct your own research.</p>

        <button
          onClick={handleAccept}
          className="bg-blue-600 hover:bg-blue-700 py-3 rounded-lg w-full text-white transition-colors"
        >
          I understand
        </button>
      </div>
    </ModalWide>
  )
}

export default LegalDisclaimer
