import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalWide } from '../../../../components/shared/Modals';
import TextField from './TextField';
import TokenDropdown from './TokenDropdown';
import NetworkDropdown from './NetworkDropdown';
import MultipleIssuerDropdown from './MultipleIssuerDropdown';
import { useTokenAllowance } from '../../../../myHooks/useTokenAllowance';

const NewModal = ({ isOpen, onClose, onSave }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    token: '',
    network: '',
    limit: '',
    issuer_ids: []
  });
  const [isProcessing, setIsProcessing] = useState(false);

  const { checkAllowance, approveToken, isApproving } = useTokenAllowance(
    formData.token,
    formData.network.toLowerCase() === 'ethereum' ? 'mainnet' : formData.network.toLowerCase() || 'base'
  );

  const handleChange = (field, value) => {
    const updates = { [field]: value };

    if (field === 'token') {
      updates.network = '';
      updates.issuer_ids = [];
    }

    if (field === 'network') {
      updates.issuer_ids = [];
    }

    setFormData(prev => ({
      ...prev,
      ...updates
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsProcessing(true);

      // Check if allowance is sufficient for all selected issuers
      const allowances = await checkAllowance(formData.limit, formData.issuer_ids);

      console.log('allowances', allowances)
      // only process approve if issuer not approved yet
      const unapprovedIssuers = allowances.filter(allowance => !allowance.approved).map(allowance => allowance.issuerId);

      if (unapprovedIssuers.length > 0) {
        // Request approval for all selected issuers
        const result = await approveToken(formData.limit, unapprovedIssuers);

        // Save spending cap
        if (result) {
          await onSave(formData);
          onClose();
        }
      } else {
        await onSave(formData);
        onClose();
      }
    } catch (error) {
      console.error('Error creating spending cap:', error);
      // You might want to show an error message to the user here
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <ModalWide
      isOpen={isOpen}
      onClose={onClose}
      title={t('settings.spending_cap.modal.new')}
      subtitle={t('settings.spending_cap.modal.new_description')}
    >
      <div className="gap-6 grid grid-cols-1 w-full">
        <TokenDropdown
          label={t('settings.spending_cap.fields.token')}
          value={formData.token}
          onChange={(value) => handleChange('token', value)}
          toolTipText={t('settings.spending_cap.fields.token_tooltip')}
        />

        <NetworkDropdown
          label={t('settings.spending_cap.fields.network')}
          value={formData.network}
          selectedToken={formData.token}
          onChange={(value) => handleChange('network', value)}
          toolTipText={t('settings.spending_cap.fields.network_tooltip')}
          disabled={!formData.token}
        />

        <MultipleIssuerDropdown
          label={t('settings.spending_cap.fields.issuers')}
          value={formData.issuer_ids}
          selectedNetwork={formData.network}
          onChange={(value) => handleChange('issuer_ids', value)}
          toolTipText={t('settings.spending_cap.fields.issuers_tooltip')}
          disabled={!formData.network}
        />

        <TextField
          label={t('settings.spending_cap.fields.limit')}
          value={formData.limit}
          onChange={(value) => handleChange('limit', +value)}
          wrapperClass="w-full"
          toolTipText={t('settings.spending_cap.fields.limit_tooltip')}
        />

        <div className="flex justify-end gap-4">
          <button
            onClick={handleSubmit}
            disabled={isProcessing || isApproving}
            className="inline-flex justify-center items-center bg-primary hover:bg-primary-hover px-6 rounded-3xl h-10 text-white disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isProcessing || isApproving ? t('Processing...') : t('Create')}
          </button>
          <button
            onClick={onClose}
            disabled={isProcessing || isApproving}
            className="inline-flex justify-center items-center bg-black-light hover:bg-gray-700 px-6 rounded-3xl h-10 text-white disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {t('Cancel')}
          </button>
        </div>
      </div>
    </ModalWide>
  );
};

export default NewModal;
