import React from 'react'
import { useMutation } from '@apollo/client'
import { motion, AnimatePresence } from 'framer-motion'
import { createPortal } from 'react-dom'
import Icons from '../../../components/shared/Icons'
import { DELETE_WALLET, GET_USER_WALLETS } from '../../../queriesAndMutations'
import { toaster } from '../../../utils'

const ActionDropdown = ({
  buttonRect,
  isOpen,
  setOpenDropdownId,
  rowData,
  refetchWallets,
}) => {
  const [deleteWallet] = useMutation(DELETE_WALLET);

  const handleDeleteWallet = async () => {
    try {
      await deleteWallet({
        variables: {
          id: rowData.id
        },
        refetchQueries: [{ query: GET_USER_WALLETS }]
      });

      refetchWallets();

      setOpenDropdownId(null);
      toaster.success('Wallet deleted successfully');
    } catch (error) {
      console.error('Error deleting wallet:', error);
      toaster.error(error.message || 'Failed to delete wallet');
    }
  };

  if (!buttonRect) return null;

  const dropdownWidth = 200;
  const windowHeight = window.innerHeight;
  const spaceBelow = windowHeight - buttonRect.bottom;

  // Position calculation
  const shouldShowAbove = spaceBelow < 150;
  const top = shouldShowAbove
    ? buttonRect.top - 8
    : buttonRect.bottom + 8;

  let left = buttonRect.right - dropdownWidth;
  if (left < 0) left = 0;
  if (left + dropdownWidth > window.innerWidth) {
    left = window.innerWidth - dropdownWidth;
  }

  // Get scroll container's scroll position
  const tableContainer = document.querySelector('.table-container');
  const scrollOffset = tableContainer ? tableContainer.scrollLeft : 0;

  // Adjust position based on scroll
  left += scrollOffset;

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: shouldShowAbove ? -10 : 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: shouldShowAbove ? -10 : 10 }}
          transition={{ duration: 0.2 }}
          style={{
            position: 'fixed',
            top: shouldShowAbove ? 'unset' : top,
            bottom: shouldShowAbove ? (windowHeight - top) : 'unset',
            left,
            zIndex: 50,
          }}
          className="flex flex-col flex-wrap justify-start items-start gap-2 border-2 border-white/10 bg-black-dark p-4 rounded-xl w-[200px]"
        >
          <button
            type="button"
            className="flex justify-start items-center gap-2 bg-transparent px-0 py-4 rounded-xl w-full h-12 font-semibold text-gray-lighter text-sm hover:text-white leading-6 tracking-[-0.005em] transition-colors duration-[0.2s] ease-linear cursor-pointer outline-none"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteWallet();
            }}
          >
            <Icons nameIcon="trash" className="w-5 h-5" />
            <span className="relative">Delete</span>
          </button>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default ActionDropdown;
