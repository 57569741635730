import React from 'react'
import SelectionCheckbox from './SelectionCheckbox'

const TableHeader = ({ selectedWallets, wallets, onSelectAll }) => {
  return (
    <thead className='bg-black-ligh min-w-full'>
      <tr>
        {/* <th scope='col' className='py-4 text-center align-middle' style={{ width: "40px"}}>
          <SelectionCheckbox
            checked={selectedWallets.length === wallets.length}
            onChange={onSelectAll}
          />
        </th> */}
        <th scope="col" className="py-4 font-sans font-semibold text-left text-sm text-white tracking-[-0.02em]" style={{ minWidth: "120px"}}>
          Address
        </th>
        <th scope="col" className="py-4 font-sans font-semibold text-center text-gray-custom text-sm tracking-[-0.02em]" style={{ minWidth: "120px", width: "20%"}}>
          Balance
        </th>
        <th scope="col" className="py-4 font-medium font-sans text-center text-gray-custom text-sm tracking-[-0.02em]" style={{ minWidth: "120px", width: "20%"}}>
          Actions
        </th>
      </tr>
    </thead>
  )
}

export default TableHeader
