import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import gql from 'graphql-tag'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'

import Heading from '../Heading'
import { CheckboxField } from '../../../components/shared/Fields'
import { useMe } from '../../../myHooks'
import { toaster } from '../../../utils'

const GET_COMPLYCUBE_INITIAL_DATA = gql`
  query getComplycubeToken($id: ID) {
    getComplycubeToken(id: $id) {
      sdk_token
      allow_new_check
    }
  }
`;

const SUBMIT_CHECK = gql`
  mutation submitComplycubeCheck($user_id: ID, $report_names: [String]!) {
    submitComplycubeCheck(user_id: $user_id, report_names: $report_names)
  }
`;

const PULL_COMPLYCUBE_DOCUMENT_AND_FACE = gql`
  mutation pullComplycubeDocumentAndFace($user_id: ID, $input: ComplycubeDocumentAndFace) {
    pullComplycubeDocumentAndFace(user_id: $user_id, input: $input)
  }
`;

const GET_COMPLYCUBE_CLIENT = gql`
  query getComplycubeClient {
    getComplycubeClient {
      id
      status
      document_capture
      face_capture
      poa_capture
    }
  }
`;

const Verification = () => {
  const { t } = useTranslation()
  const [isChecked, setIsChecked] = useState(false)
  const { data: { me: user } = {} } = useMe()
  const [startComplyCube, setStartComplyCube] = useState(false)
  const [getComplycubeInitialData] = useLazyQuery(GET_COMPLYCUBE_INITIAL_DATA);
  const { data: clientData, loading: clientLoading } = useQuery(GET_COMPLYCUBE_CLIENT);

  const [submitCheck] = useMutation(SUBMIT_CHECK);
  const [pullOnfidoDocumentAndFace] = useMutation(
    PULL_COMPLYCUBE_DOCUMENT_AND_FACE
  );

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked)
  }

  if (!user) {
    return <></>
  }

  const initComplyCube = async (sdk_token) => {
    console.log("initComplyCube")

    window.ComplyCube.mount({
      token: sdk_token,
      stages: [
        'intro',
        {
          name: 'documentCapture',
          options: {
            crossDeviceOnly: true,
            documentTypes: {
              passport: true,
              national_identity_card: true,
              driving_license: false,
              residence_permit: false,
            }
          },
        },
        {
          name: 'faceCapture',
          options: {
            mode: "video"
          },
        },
        {
          name: 'completion',
          options: {
            heading: t('settings.verification.liveness.complete')
          },
        },
      ],
      onComplete: async function (data) {
        console.log("Capture complete", data)
        try {
          await pullOnfidoDocumentAndFace({
            variables: {
              user_id: user.id,
              input: data
            },
          })

          const reportNames = [
            "document_check",
            "enhanced_identity_check",
          ]

          await submitCheck({
            variables: {
              user_id: user.id,
              report_names: reportNames
            }
          });
        } catch (error) {
          console.log(error)
        }
      },
      onModalClose: function () {
        window.location.reload();
      },
    });
  };

  const handleStartComplyCube = async () => {
    try {
      const { data } = await getComplycubeInitialData({
        variables: {
          id: user.id,
        }
      })

      if (data && data.getComplycubeToken && data.getComplycubeToken.sdk_token) {
        setStartComplyCube(true)
        initComplyCube(data.getComplycubeToken.sdk_token)
      }
    } catch (error) {
      console.log(error)
      toaster.error(error.message)
    }
  }

  const renderVerificationStatus = () => {
    if (clientLoading) return null;

    const client = clientData && clientData.getComplycubeClient;
    if (!client) return null;

    if (client.status === 'COMPLETED') {
      return (
        <div className="flex flex-col items-center justify-center bg-green-500/20 px-8 py-6 rounded-2xl w-full text-center text-white mb-6">
          <div className="text-xl font-semibold mb-2">
            {t('settings.verification.status.completed')}
          </div>
          <div className="text-white/70">
            {t('settings.verification.status.completed_description')}
          </div>
        </div>
      );
    }

    return null;
  };

  if (startComplyCube) {
    return (
      <div className="card card__onfido">
        <div id="complycube-mount" className="onfido-mount" />
        <br/>
      </div>
    )
  }

  return (
    <>
      <Heading
        title={t('settings.verification.title')}
        description={t('settings.verification.description')}
      />

      {renderVerificationStatus()}

      {(!clientData || !clientData.getComplycubeClient || clientData.getComplycubeClient.status !== 'COMPLETED') && (
        <>
          <div className="flex flex-col flex-wrap justify-center items-center bg-white/20 px-8 py-12 rounded-2xl w-full text-center text-white">
            <div className="mx-auto w-full max-w-screen-sm">

              <div className="m-0 mb-6 font-sans font-semibold text-center text-white text-xl leading-7 tracking-[-0.02em]">
                {t('settings.verification.liveness.title')}
              </div>
              <ol className="flex flex-row flex-wrap justify-start items-start gap-1 mb-8 font-sans text-left text-white/70 tracking-[-0.02em]">
                <li>{t("settings.verification.liveness.acknowledge.one")}</li>
                <li>{t("settings.verification.liveness.acknowledge.two")}</li>
              </ol>

              <CheckboxField
                name="acknowledge-reviewed-and-accept"
                label={t('settings.verification.liveness.confirm')}
                isTextWhite={true}
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            </div>
          </div>

          <div className="mt-6 text-center">
            <button
              className={cn(
                "inline-flex justify-center items-center gap-4 px-6 py-4 rounded-3xl font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] outline-none m-0",
                {
                  "bg-gray-custom/20 cursor-not-allowed": !isChecked,
                  "bg-primary hover:bg-primary-hover hover:opacity-90 cursor-pointer overflow-visible": isChecked
                }
              )}
              disabled={!isChecked}
              onClick={handleStartComplyCube}
            >
              {t("settings.verification.liveness.continue_confirmation")}
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default Verification
