import { gql } from '@apollo/client';

export const CREATE_WALLET = gql`
  mutation CreateWallet($wallet_address: String!, $chain_index: String) {
    createWallet(wallet_address: $wallet_address, chain_index: $chain_index) {
      id
      wallet_address
      chain_index
      tokens {
        id
        symbol
        contract_address
        entry_price
        current_price
        quantity
        chain_index
      }
    }
  }
`;

export const UPDATE_WALLET = gql`
  mutation UpdateWallet($id: ID!, $wallet_address: String, $chain_index: String) {
    updateWallet(id: $id, wallet_address: $wallet_address, chain_index: $chain_index) {
      id
      wallet_address
      chain_index
      tokens {
        id
        symbol
        contract_address
        entry_price
        current_price
        quantity
        chain_index
      }
    }
  }
`;

export const DELETE_WALLET = gql`
  mutation DeleteWallet($id: ID!) {
    deleteWallet(id: $id)
  }
`;

export const CREATE_WALLET_TOKEN = gql`
  mutation CreateWalletToken($wallet_id: ID!, $token: WalletTokenInput!) {
    createWalletToken(wallet_id: $wallet_id, token: $token) {
      id
      wallet_id
      symbol
      contract_address
      entry_price
      current_price
      quantity
      chain_index
    }
  }
`;

export const UPDATE_WALLET_TOKEN = gql`
  mutation UpdateWalletToken($id: ID!, $token: WalletTokenInput!) {
    updateWalletToken(id: $id, token: $token) {
      id
      wallet_id
      symbol
      contract_address
      entry_price
      current_price
      quantity
      chain_index
    }
  }
`;

export const DELETE_WALLET_TOKEN = gql`
  mutation DeleteWalletToken($id: ID!) {
    deleteWalletToken(id: $id)
  }
`;

export const BULK_CREATE_WALLET_TOKENS = gql`
  mutation BulkCreateWalletTokens($wallet_id: ID!, $tokens: [WalletTokenInput]!) {
    bulkCreateWalletTokens(wallet_id: $wallet_id, tokens: $tokens) {
      id
      wallet_id
      symbol
      contract_address
      entry_price
      current_price
      quantity
      chain_index
    }
  }
`;

export const BULK_UPDATE_WALLET_TOKENS = gql`
  mutation BulkUpdateWalletTokens($wallet_id: ID!, $tokens: [WalletTokenInput]!) {
    bulkUpdateWalletTokens(wallet_id: $wallet_id, tokens: $tokens) {
      id
      wallet_id
      symbol
      contract_address
      entry_price
      current_price
      quantity
      chain_index
    }
  }
`;

export const SYNC_TRANSACTIONS = gql`
  mutation SyncTransactions($wallet_id: ID!) {
    syncTransactions(wallet_id: $wallet_id)
  }
`;
