import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import Icons from '../../../components/shared/Icons';
import Heading from '../Heading';
import Table from './components/Table';
import DeleteModal from './components/DeleteModal';
import EditModal from './components/EditModal';
import NewModal from './components/NewModal';
import { GET_SPENDING_CAPS, UPDATE_SPENDING_CAP, DELETE_SPENDING_CAP, CREATE_SPENDING_CAP } from '../../../queriesAndMutations';
import styles from './style.module.scss';
import issuers from '../../../services/dummyDataIssuers';

const SpendingCap = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalNewOpen, setIsModalNewOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [buttonRect, setButtonRect] = useState(null);

  // Query spending caps
  const { data, loading, refetch } = useQuery(GET_SPENDING_CAPS);

  // Mutations
  const [createSpendingCap] = useMutation(CREATE_SPENDING_CAP);
  const [updateSpendingCap] = useMutation(UPDATE_SPENDING_CAP);
  const [deleteSpendingCap] = useMutation(DELETE_SPENDING_CAP);

  const handleDropdownClick = (e, tokenId) => {
    e.stopPropagation();
    const rect = e.currentTarget.getBoundingClientRect();
    setButtonRect(rect);
    setOpenDropdownId(openDropdownId === tokenId ? null : tokenId);
  };

  const handleDocumentClick = useCallback((e) => {
    if (!e.target.closest('.dropdown-trigger')) {
      setOpenDropdownId(null);
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => document.removeEventListener('click', handleDocumentClick);
  }, [handleDocumentClick]);

  const handleNavigateToDetail = (e, token, cap) => {
    e.preventDefault();
    setSelectedItem({
      token: token.token,
      network: cap.network,
      limit: cap.limit,
      used: cap.used
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteSpendingCap({
        variables: {
          token: selectedItem.token,
          network: selectedItem.network
        }
      });
      refetch();
      handleCloseModal();
    } catch (error) {
      console.error('Error deleting spending cap:', error);
    }
  };

  const handleNavigateToInvestment = (e, item) => {
    e.preventDefault();

    setEditItem(item);
    setIsModalEditOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsModalEditOpen(false);
    setEditItem(null);
  };

  const handleSaveEdit = async (formData) => {
    try {
      await updateSpendingCap({
        variables: {
          input: {
            id: editItem.id,
            limit: formData.limit || editItem.limit,
            issuer_ids: formData.issuer_ids || editItem.issuer_ids
          }
        }
      });
      refetch();
      handleCloseEditModal();
    } catch (error) {
      console.error('Error updating spending cap:', error);
    }
  };

  const handleAddNew = () => {
    setIsModalNewOpen(true);
  };

  const handleCloseNewModal = () => {
    setIsModalNewOpen(false);
  };

  const handleSaveNew = async (formData) => {
    try {
      await createSpendingCap({
        variables: {
          input: {
            token: formData.token,
            network: formData.network,
            limit: formData.limit,
            issuer_ids: formData.issuer_ids
          }
        }
      });
      refetch();
      handleCloseNewModal();
    } catch (error) {
      console.error('Error creating spending cap:', error);
    }
  };

  // Transform data for table
  const transformedData = React.useMemo(() => {
    if (!data || !data.getSpendingCaps) return [];

    const groupedByToken = data.getSpendingCaps.reduce((acc, cap) => {
      if (!acc[cap.token]) {
        acc[cap.token] = {
          token: cap.token,
          caps: []
        };
      }
      acc[cap.token].caps.push({
        ...cap,
        issuers: (cap.issuer_ids || []).map(issuerId => issuers.find(issuer => issuer.id === issuerId))
      });
      return acc;
    }, {});

    return Object.values(groupedByToken);
  }, [data]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Heading
        title={t('settings.spending_cap.title')}
        description={t('settings.spending_cap.description')}
      />

      <div className={`relative border-2 border-white/10 border-solid rounded-xl w-full max-w-full overflow-x-auto ${styles.wrapper}`}>
        <Table
          data={transformedData}
          buttonRect={buttonRect}
          openDropdownId={openDropdownId}
          setOpenDropdownId={setOpenDropdownId}
          handleDropdownClick={handleDropdownClick}
          handleNavigateToDetail={handleNavigateToDetail}
          handleNavigateToInvestment={handleNavigateToInvestment}
        />
      </div>

      <div className="flex justify-end mt-6">
        <button
          onClick={handleAddNew}
          className="inline-flex justify-center items-center bg-primary hover:bg-primary-hover hover:opacity-90 m-0 px-6 rounded-3xl h-10 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible outline-none"
        >
          <Icons nameIcon="plus" className="w-4 h-4" />
          {t('settings.spending_cap.fields.new')}
        </button>
      </div>

      <DeleteModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        selectedItem={selectedItem}
        onConfirm={handleConfirmDelete}
      />

      {
        editItem && (
          <EditModal
            isOpen={isModalEditOpen}
            onClose={handleCloseEditModal}
            editItem={editItem}
            onSave={handleSaveEdit}
          />
        )
      }

      <NewModal
        isOpen={isModalNewOpen}
        onClose={handleCloseNewModal}
        onSave={handleSaveNew}
      />
    </>
  );
};

export default SpendingCap;
