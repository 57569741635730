import { gql } from '@apollo/client';

export const GET_SPENDING_CAPS = gql`
  query GetSpendingCaps {
    getSpendingCaps {
      id
      token
      network
      limit
      used
      issuer_ids
    }
  }
`;

export const GET_SPENDING_CAP = gql`
  query GetSpendingCap($token: String!, $network: String!) {
    getSpendingCap(token: $token, network: $network) {
      id
      token
      network
      limit
      used
      issuer_ids
    }
  }
`;