/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_USER_WALLETS, GET_TOKEN_BALANCES_BY_ADDRESS, GET_HISTORICAL_PRICE } from '../../../queriesAndMutations';
import { createRoot } from 'react-dom/client';
import Avatar from 'react-avatar';

// import VolumeFilter from '../../DashboardTokens/VolumeFilter';
import AddWallet from '../../DashboardTokens/AddWallet';
import AdvancedFilter from '../../DashboardTokens/AdvancedFilter';
import ViewModeToggle from './ViewModeToggle';
import AssetsTable from "./AssetsTable";
import WalletAssetsView from './WalletAssetsView';
import useViewMode from "./hooks/useViewMode"
import { NETWORK_IMAGES } from '../../../constant/images';
import tokenList from "../../../services/listDataToken";
import styles from './MyAssets.module.scss';

const MyAssets = ({ setTotalBalance, timeRange }) => {
  const { t } = useTranslation();
  const { viewMode, toggleViewMode, isCombinedView, isByWalletView } = useViewMode();
  const { loading: walletsLoading, data: walletsData } = useQuery(GET_USER_WALLETS);
  const [getTokenBalances] = useLazyQuery(GET_TOKEN_BALANCES_BY_ADDRESS);
  const [getHistoricalPrice] = useLazyQuery(GET_HISTORICAL_PRICE);

  const [assets, setAssets] = useState([]);
  const [assetsChange, setAssetsChange] = useState([]);
  const [walletViews, setWalletViews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [volumeFilter, setVolumeFilter] = useState(timeRange);

  useEffect(() => {
    setVolumeFilter(timeRange);
  }, [timeRange]);

  const getNetworkName = (chainIndex) => {
    const networks = {
      '137': 'Polygon',
      '8453': 'Base',
      '1': 'Ethereum',
      '11155111': 'Ethereum',
      '56': 'BNB Chain',
      '43114': 'Avalanche C',
      '10': 'Optimism',
      '42161': 'Arbitrum One',
      '100': 'Gnosis',
    };
    return networks[chainIndex] || 'Unknown';
  };

  const getTokenImage = (tokenSymbol) => {
    try {
      const token = tokenList.find(token => token.symbol.toLowerCase() === tokenSymbol.toLowerCase());
      if (!token) return null;

      // Get the actual image file
      const imgSrc = require(`../../../assets/images/tokenSymbol/${token.image}`);
      return imgSrc.default || imgSrc; // Handle both ESM and CommonJS modules
    } catch (err) {
      return NETWORK_IMAGES.defaultToken;
    }
  };

  const getTimeRange = (filter) => {
    const now = Date.now();
    switch (filter) {
      case '1M':
        return {
          begin: now - (30 * 24 * 60 * 60 * 1000), // 30 days ago
          period: '1d'
        };
      case '6M':
        return {
          begin: now - (180 * 24 * 60 * 60 * 1000), // 180 days ago
          period: '1d'
        };
      case '12M':
        return {
          begin: now - (360 * 24 * 60 * 60 * 1000), // 360 days ago
          period: '1d'
        };
      case '24M':
        return {
          begin: now - (720 * 24 * 60 * 60 * 1000), // 720 days ago
          period: '1d'
        };
      default:
        return {
          begin: now - (30 * 24 * 60 * 60 * 1000), // default to 30 days
          period: '1d'
        };
    }
  };

  const calculatePriceChange = async (token) => {
    try {
      const timeRange = getTimeRange(volumeFilter);

      const { data } = await getHistoricalPrice({
        variables: {
          input: {
            tokenAddress: token.contract_address || "",
            chainIndex: parseInt(token.chain_index),
            limit: 1,
            begin: timeRange.begin.toString(),
            end: (timeRange.begin + 24 * 60 * 60 * 1000).toString(),
            period: timeRange.period
          }
        }
      });

      if (data && data.getHistoricalPrice && data.getHistoricalPrice.historicalPrice) {
        const price = data.getHistoricalPrice.historicalPrice;
        if (price) {
          const oldPrice = parseFloat(price);

          // new pric is current price
          const newPrice = token.price;
          return ((newPrice - oldPrice) / oldPrice) * 100;
        }
      }
      return 0;
    } catch (error) {
      console.error('Error fetching historical price:', error);
      return 0;
    }
  };

  const processWalletData = async (wallets, tokenPrices) => {
    const walletViews = wallets.map(wallet => {
      const walletAssets = wallet.tokens.map(token => {
        const key = `${token.symbol.replaceAll(/[\s\W]/g, '')}-${token.chain_index}-${token.contract_address}`;
        const priceData = tokenPrices[key];

        return {
          symbol: token.symbol,
          chain_index: token.chain_index,
          balance: (priceData && priceData.balance) || 0,
          price: (priceData && priceData.price) || 0,
          allocation: 0, // Will calculate after
        };
      });

      const totalValue = walletAssets.reduce((sum, asset) => sum + (asset.balance * asset.price), 0);

      // Calculate allocations
      walletAssets.forEach(asset => {
        asset.allocation = totalValue > 0 ? (((asset.balance * asset.price) / totalValue) * 100) : 0;
      });

      return {
        wallet_address: wallet.wallet_address,
        totalValue,
        assets: walletAssets,
      };
    });

    setWalletViews(walletViews);
  };

  const transformTokensToAssets = async (wallets, tokenPrices) => {
    if (!wallets) return [];

    // Create a map to accumulate token quantities across wallets
    const tokenMap = new Map();

    // First, process stored tokens from all wallets
    wallets.forEach(wallet => {
      wallet.tokens.forEach(token => {
        // replace all spaces with empty string, all special characters with empty string
        const key = `${token.symbol.replaceAll(/[\s\W]/g, '')}-${token.chain_index}-${token.contract_address}`;
        if (!tokenMap.has(key)) {
          tokenMap.set(key, {
            symbol: token.symbol,
            chain_index: token.chain_index,
            contract_address: token.contract_address,
            quantity: 0,
            current_price: parseFloat(token.current_price || 0),
            entry_price: parseFloat(token.entry_price || 0),
            wallet_addresses: new Set(),
            hasPrice: parseFloat(token.current_price || 0) > 0
          });
        }
      });
    });

    // Then, process real-time token balances
    Object.entries(tokenPrices).forEach(([key, priceData]) => {
      const [symbol, chainIndex, contractAddress] = key.split('-');
      const mapKey = `${symbol}-${chainIndex}-${contractAddress}`;

      if (!tokenMap.has(mapKey)) {
        tokenMap.set(mapKey, {
          symbol,
          chain_index: chainIndex,
          contract_address: contractAddress,
          quantity: 0,
          current_price: parseFloat(priceData.price || 0),
          wallet_addresses: new Set(),
          hasPrice: parseFloat(priceData.price || 0) > 0
        });
      }

      const existingToken = tokenMap.get(mapKey);
      existingToken.quantity += parseFloat(priceData.balance || 0);
      existingToken.current_price = parseFloat(priceData.price || existingToken.current_price || 0);
      existingToken.wallet_addresses.add(priceData.wallet_address);
      existingToken.hasPrice = existingToken.hasPrice || parseFloat(priceData.price || 0) > 0;
    });

    // Convert map to array and format for display
    const assetsArray = Array.from(tokenMap.values())
      .map(token => {
        const tokenValue = token.quantity * token.current_price;
        const icon = getTokenImage(token.symbol);

        return {
          id: `${token.symbol.replaceAll(/[\s\W]/g, '')}-${token.chain_index}-${token.contract_address}`,
          name: token.symbol,
          symbol: token.symbol,
          chain_index: token.chain_index,
          contract_address: token.contract_address,
          icon,
          balance: {
            amount: tokenValue,
            units: `${token.quantity.toFixed(6)} ${token.symbol}`
          },
          price: {
            entry_price: token.entry_price,
            amount: token.current_price,
            change: 0 // Will be updated later
          },
          allocation: 0, // Will be calculated after
          wallet_count: token.wallet_addresses.size,
          hasPrice: token.hasPrice
        };
      });

    // Calculate total value and allocations
    const totalValue = assetsArray.reduce((sum, asset) => sum + asset.balance.amount, 0);
    setTotalBalance(totalValue);

    // Sort assets: first by value (if has price), then by name
    const sortedAssets = assetsArray.sort((a, b) => {
      if (a.hasPrice && b.hasPrice) {
        return b.balance.amount - a.balance.amount;
      }
      if (a.hasPrice) return -1;
      if (b.hasPrice) return 1;
      return a.name.localeCompare(b.name);
    });

    return sortedAssets.map(asset => ({
      ...asset,
      allocation: totalValue > 0 ? ((asset.balance.amount / totalValue) * 100) : 0
    }));
  };

  const AssetImage = ({ icon, name }) => (
    <div className="flex-shrink-0 rounded-full w-6 h-6">
      {icon && !String(icon).includes('default-icon-day-v3.svg') ? (
        <img
          src={typeof icon === 'string' ? icon : (icon.default || icon)}
          alt={name}
          className="rounded-full w-full h-full object-cover"
          onError={(e) => {
            e.target.onerror = null;
            e.target.style.display = 'none';
            const avatar = document.createElement('div');
            e.target.parentNode.appendChild(avatar);
            const root = createRoot(avatar);
            root.render(
              <Avatar
                name={name}
                size="24"
                round={true}
                className="w-full h-full object-cover"
              />
            );
          }}
        />
      ) : (
        <Avatar
          name={name}
          size="24"
          round={true}
          className="w-full h-full object-cover"
        />
      )}
    </div>
  );

  useEffect(() => {
    const fetchTokenPrices = async () => {
      if (!walletsData || !walletsData.getUserWallets) return;

      try {
        setLoading(true);
        const wallets = walletsData.getUserWallets;
        const walletAddresses = [...new Set(wallets.map(w => w.wallet_address))];

        const priceResults = await Promise.all(
          walletAddresses.map(address => getTokenBalances({ variables: { address } }))
        );

        const tokenPrices = {};

        priceResults.forEach(result => {
          if (result.data && result.data.getTokenBalancesByAddress && result.data.getTokenBalancesByAddress.tokenAssets) {
            result.data.getTokenBalancesByAddress.tokenAssets.forEach(token => {
              const key = `${token.symbol.replaceAll(/[\s\W]/g, '')}-${token.chainIndex}-${token.tokenAddress}`;

              if (!tokenPrices[key]) {
                tokenPrices[key] = {
                  price: parseFloat(token.tokenPrice),
                  balance: parseFloat(token.balance),
                  wallet_address: result.data.getTokenBalancesByAddress.address
                };
              } else {
                tokenPrices[key].balance += parseFloat(token.balance);
              }
            });
          }
        });

        await processWalletData(wallets, tokenPrices);
        const transformedAssets = await transformTokensToAssets(wallets, tokenPrices);
        setAssets(transformedAssets);
      } catch (error) {
        console.error('Error fetching token prices:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTokenPrices();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletsData, getTokenBalances])

  useEffect(() => {
    const updatePriceChanges = async () => {
      if (!assets.length) return;

      setLoading(true);
      const updatedAssets = [];

      for (const asset of assets) {
        if (+asset.price.amount !== 0 || +asset.price.entry_price !== 0) {
          // sleep 0.2s to avoid rate limit
          await new Promise((resolve) => setTimeout(resolve, 100));

          let priceChange = 0;
          if (asset.price.entry_price) {
            priceChange = ((asset.price.amount - asset.price.entry_price) / asset.price.entry_price) * 100;
          } else {
            priceChange = await calculatePriceChange({
              contract_address: asset.contract_address,
              chain_index: asset.chain_index,
              price: asset.price.amount,
              entry_price: asset.price.entry_price
            });
          }

          updatedAssets.push({
            ...asset,
            price: {
              ...asset.price,
              change: parseFloat(priceChange.toFixed(2))
            }
          });
        } else {
          updatedAssets.push(asset);
        }
      }

      setAssetsChange(updatedAssets);
      setLoading(false);
    };

    updatePriceChanges();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volumeFilter, assets]);

  if (loading) return <div>Loading...</div>;
  if (!walletsData || !walletsData.getUserWallets) return <div>Error loading assets</div>;

  return (
    <div className={styles.wrapper}>
      <div className="mb-6">
        <div className="mb-2 font-sans font-semibold text-lg text-start text-white leading-6 tracking-[-0.02em]">Assets</div>

        <div className="flex max-480:flex-col-reverse max-480:flex-wrap justify-between items-center gap-4 mb-6">
          <ViewModeToggle viewMode={viewMode} onToggle={toggleViewMode} />
          {/* <VolumeFilter setVolumeFilter={setVolumeFilter} volumeFilter={volumeFilter} /> */}

          <div className="flex max-480:flex-row flex-wrap justify-start items-center gap-4 max-480:w-full">
            <AddWallet />
            <AdvancedFilter />
          </div>
        </div>

      </div>

      <AnimatePresence mode="wait">
        {isCombinedView ? (
          <AssetsTable
            key="combined"
            assets={assetsChange}
            getNetworkName={getNetworkName}
          />
        ) : (
          <WalletAssetsView
            key="by-wallet"
            wallets={walletViews}
            getNetworkName={getNetworkName}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default MyAssets;
