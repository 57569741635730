import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { useTranslation } from 'react-i18next'
import Icons from '../../../../components/shared/Icons';
import tokensList from '../../../../services/dummyDataToken';

const TokenDropdown = ({
  wrapperClass,
  toolTipText,
  label,
  value,
  disabled = false,
  onChange
}) => {
  const { t } = useTranslation();

  const tokens = tokensList.map((token) => ({
    value: token.symbol.toUpperCase(),
    label: token.name.toUpperCase(),
  }));

  const [dropdownDirection, setDropdownDirection] = useState('down');
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selectedToken, setSelectedToken] = useState(value);
  const [isFocused, setIsFocused] = useState(false);

  const dropdownContentRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpenDropdown(false);
    }
  };

  const handleEscPress = (event) => {
    if (event.key === 'Escape') {
      setIsOpenDropdown(false);
    }
  };

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpenDropdown(prevState => !prevState);
    }
  };

  const handleTokenSelect = (token) => {
    setSelectedToken(token);
    setIsOpenDropdown(false);
    onChange(token);
  };

  useEffect(() => {
    if (isOpenDropdown) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - dropdownRect.bottom;
      const spaceAbove = dropdownRect.top;
      const calculatedHeight = dropdownContentRef.current ? dropdownContentRef.current.scrollHeight + 40 : 300;

      setDropdownDirection(spaceBelow < calculatedHeight && spaceAbove > spaceBelow ? 'up' : 'down');

      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscPress);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscPress);
    };
  }, [isOpenDropdown]);

  return (
    <div className={`relative ${wrapperClass || ''}`} ref={dropdownRef}>
      {label && (
        <label className={`flex flex-row flex-wrap gap-1 mb-3 text-[13px] leading-[1.2] tracking-[-0.02em] transition-colors duration-[0.2s] font-semibold font-sans ${isFocused ? 'text-white' : 'text-gray-lighter'} ${disabled ? 'text-white' : 'text-gray-lighter'}`}>
          {label}
          {toolTipText && (
            <>
              <span className='relative top-[1px] cursor-pointer' data-tooltip-id="token-tooltip">
                <Icons nameIcon='alert-circle' className="w-[14px] h-[14px]" />
              </span>
              <Tooltip
                id="token-tooltip"
                className="!inline-flex !justify-center !items-center !bg-black-light !px-2 !py-[2px] !rounded-md !font-bold !text-white !text-xs"
              >
                {toolTipText}
              </Tooltip>
            </>
          )}
        </label>
      )}

      <div className="relative w-full">
        <div
          className={`inline-flex relative justify-between items-center gap-1 p-4 w-full text-base leading-6 font-normal font-sans text-white text-start bg-black-dark ease-linear rounded-xl border-2 border-solid min-w-0 transition-all duration-[0.2s] ${disabled ? 'cursor-not-allowed opacity-50 border-white/30' : 'cursor-pointer border-white/10'} ${isFocused ? 'border-white/30' : 'border-white/10'}`}
          onClick={toggleDropdown}
        >
          {selectedToken || t('settings.spending_cap.fields.select_token')}
          <Icons
            nameIcon="chevron-down"
            className={`w-4 h-4 ${isOpenDropdown ? 'rotate-180' : ''}`}
          />
        </div>

        <AnimatePresence>
          {isOpenDropdown && (
            <motion.div
              ref={dropdownContentRef}
              initial={{ opacity: 0, translateY: dropdownDirection === 'down' ? -16 : 16, visibility: 'hidden' }}
              animate={{ opacity: 1, translateY: 0, visibility: 'visible' }}
              exit={{ opacity: 0, translateY: dropdownDirection === 'down' ? -16 : 16, visibility: 'hidden' }}
              transition={{ duration: 0.2 }}
              className={`absolute ${dropdownDirection === 'up' ? 'bottom-full mb-0.5' : 'top-full mt-0.5'} z-[9] inset-x-0 bg-[#1e2025] shadow-[#ffffff33_0_0_0_2px_inset] p-6 border-solid rounded-xl w-full overflow-hidden`}
            >
              <div className="border-0 -mx-6 my-0 px-6 py-0 max-h-80 overflow-y-auto scroll-smooth scrollbar-custom">
                {tokens.map((token) => (
                  <div
                    key={token.value}
                    className="flex flex-row flex-wrap flex-[1_1_0] justify-start items-center border-0 hover:opacity-80 m-0 py-2 p-0 font-sans font-semibold text-white cursor-pointer"
                    onClick={() => handleTokenSelect(token.label)}
                  >
                    {token.label}
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default TokenDropdown;
