import { gql } from '@apollo/client';

export const ADD_IP_WHITELIST = gql`
  mutation AddIpWhitelist($ip_address: String!, $description: String) {
    addIpWhitelist(ip_address: $ip_address, description: $description) {
      id
      ip_address
      description
      is_active
      createdAt
      updatedAt
    }
  }
`;

export const REMOVE_IP_WHITELIST = gql`
  mutation RemoveIpWhitelist($id: Int!) {
    removeIpWhitelist(id: $id)
  }
`;

export const TOGGLE_IP_WHITELIST = gql`
  mutation ToggleIpWhitelist($id: Int!) {
    toggleIpWhitelist(id: $id) {
      id
      ip_address
      description
      is_active
      createdAt
      updatedAt
    }
  }
`;
