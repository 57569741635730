/* eslint-disable import/no-extraneous-dependencies */
import { http, useAccount, useConnect, useDisconnect, useSignMessage } from 'wagmi';
import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { coinbaseWallet, injected, walletConnect } from 'wagmi/connectors';
import { useCapabilities, useWriteContracts } from 'wagmi/experimental';
import contractAbi from '../../../../../constants/smartContract';
import { base, baseSepolia, sepolia } from '@wagmi/core/chains'
import { WC_PROJECT_ID } from '../../../../../config'
import { useWagmiConfig } from '../../../../../myHooks/useWagmi'
import { getAccount, createConfig, getTransaction } from '@wagmi/core'
import { isProduction } from '../../../../../utils';
import { getCoinAddress } from '../../../../../myHooks/useWeb3Provider';

export const useCoinbaseTrans = () => {
  const wagmiConfig = useWagmiConfig();

  const { address, isConnected } = useAccount();
  const { connect } = useConnect();
  const { disconnect } = useDisconnect();
  const [isProcessing, setIsProcessing] = useState(false);
  const { signMessageAsync } = useSignMessage();

  // Configure `useWriteContracts` to call the mint function on the contract
  const { writeContractsAsync } = useWriteContracts();

  // Check for paymaster capabilities with `useCapabilities`
  const { data: availableCapabilities, isLoading } = useCapabilities({
    account: address,
  });

  const availableCapabilitiesRef = useRef(null);

  useEffect(() => {
    if (availableCapabilities) {
      availableCapabilitiesRef.current = availableCapabilities;
    }
  }, [availableCapabilities]);

  const handleApprove = async (value, currency, currentIssuer) => {
    if (!isConnected) {
      await wagmiConfig.connectors[0].connect();
      await connect({ connector: wagmiConfig.connectors[0] })
    }

    setIsProcessing(true);
    const coinAddress = getCoinAddress(currency, currentIssuer.network)

    const smartContractAddress = currentIssuer.contract || process.env.REACT_APP_SMART_CONTRACT_ADDRESS

    const abi = [
      {
        "constant": false,
        inputs: [
          { name: 'spender', type: 'address' },
          { name: 'value', type: 'uint256' }
        ],
        name: 'approve',
        outputs: [
          { name: '', type: 'bool' }
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      },
    ];

    try {
      console.log('before writeContracts')

      const capabilitiesForChain = availableCapabilitiesRef.current
        ? availableCapabilitiesRef.current[isProduction ? base.id : baseSepolia.id]
        : null;

      const dynamicCapabilities = capabilitiesForChain && capabilitiesForChain.paymasterService.supported
      ? {
          paymasterService: {
            url: process.env.REACT_APP_PAYMASTER_AND_BUNDLER_ENDPOINT,
          },
        }
      : {};

      console.log('dynamicCapabilities', dynamicCapabilities);

      const result = await writeContractsAsync({
        contracts: [
          {
            address: coinAddress,
            abi,
            functionName: 'approve',
            args: [smartContractAddress, BigInt(value).toString()],
          },
        ],
        chainId: isProduction ? base.id : baseSepolia.id,
        capabilities: dynamicCapabilities,
        options: {
          paymasterService: dynamicCapabilities.paymasterService,
        },
      });

      setIsProcessing(false);
      return result; // Return the hash instead of the full result
    } catch (error) {
      console.error('Approving failed:', error);
      setIsProcessing(false);

      return null;
    }
  };

  const handleSignMessage = async (message) => {
    console.log('address', address);

    console.log('isConnected', isConnected)

    if (!isConnected) {
      console.log('connect')
      console.log('wagmiConfig.connectors', wagmiConfig.connectors[0])
      await wagmiConfig.connectors[0].connect();
      await connect({ connector: wagmiConfig.connectors[0] })
    }

    try {
      console.log('before handleSignMessage')
      const result = await signMessageAsync({
        message,
      })

      setIsProcessing(false);
      return result; // Return the hash instead of the full result
    } catch (error) {
      console.error('Approving failed:', error);
      setIsProcessing(false);

      return null;
    }
  };

  const handleBuyToken = async (currencyAmount, coinEnum) => {
    console.log('handleBuyToken');
    setIsProcessing(true);
    const smartContractAddress = process.env.REACT_APP_SMART_CONTRACT_ADDRESS

    const capabilitiesForChain = availableCapabilitiesRef.current
      ? availableCapabilitiesRef.current[isProduction ? base.id : baseSepolia.id]
      : null;

    const dynamicCapabilities = capabilitiesForChain && capabilitiesForChain.paymasterService.supported
      ? {
          paymasterService: {
            url: process.env.REACT_APP_PAYMASTER_AND_BUNDLER_ENDPOINT,
          },
        }
      : {};

    try {
      console.log('dynamicCapabilities', dynamicCapabilities);

      const result = await writeContractsAsync({
        contracts: [
          {
            address: smartContractAddress,
            abi: contractAbi.crowdsale,
            functionName: 'buyTokens',
            args: [address, BigInt(currencyAmount).toString(), `${coinEnum}`],
          },
        ],
        chainId: isProduction ? base.id : baseSepolia.id,
        capabilities: dynamicCapabilities,
        options: {
          paymasterService: dynamicCapabilities.paymasterService,
        },
      });

      setIsProcessing(false);
      return result; // Return the hash instead of the full result
    } catch (error) {
      console.error('Approving failed:', error);
      setIsProcessing(false);

      return null;
    }
  };

  // useEffect(() => {
  //   console.log('xxxxx')
  //   const fetchTrans = async () => {
  //     const transaction = await getTransaction(wagmiConfig, {
  //       hash: '0x60e447c6f4f58d62e0fd52e2dd080ea6abf1b0895104d2a146fad8deeea8db4c0000000000000000000000000000000000000000000000000000000000014a34',
  //       index: 0,
  //       chainId: BASE_SEPOLIA_CHAIN_ID,
  //     });

  //     console.log('transaction', transaction)
  //   }

  //   fetchTrans();
  // }, [wagmiConfig])

  return {
    isConnected,
    connect,
    disconnect,
    handleApprove,
    isProcessing,
    handleBuyToken,
    handleSignMessage,
  }
}

export default useCoinbaseTrans;
