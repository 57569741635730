import { gql } from '@apollo/client';

export const GET_INVESTMENT = gql`
  query GetInvestment($id: Int!) {
    getInvestment(id: $id) {
      id
      status
    }
  }
`;

export const GET_LAST_INVESTMENT = gql`
  query GetLastInvestment {
    getLastInvestment {
      id
      amount
      currency
      token_amount
      token_name
      status
      first_name
      last_name
      birth_date
      place_of_birth
      nationality
      residential_address
      residential_address_two
      city
      postal_code
      country_of_residence
      wallet_address
      email
    }
  }
`;

export const GET_TRANSACTIONS_BY_ADDRESS = gql`
  query GetTransactionsByAddress {
    getTransactionsByAddress {
      transactions
    }
  }
`;

export const GET_TOKEN_BALANCES_BY_ADDRESS = gql`
  query GetTokenBalancesByAddress($address: String) {
    getTokenBalancesByAddress(address: $address) {
      tokenAssets
    }
  }
`;

export const GET_TOTAL_VALUE_BY_ADDRESS = gql`
  query GetTotalValueByAddress($address: String) {
    getTotalValueByAddress(address: $address) {
      totalValue
    }
  }
`;

export const GET_INVESTMENT_COMMISSION = gql`
  query GetInvestmentCommission {
    getInvestmentCommission {
      id
      amount
      currency
      token_amount
      token_name
      status
      tx_hash
      createdAt
      updatedAt
      first_name
      last_name
      birth_date
      place_of_birth
      nationality
      residential_address
      residential_address_two
      city
      postal_code
      country_of_residence
      wallet_address
    }
  }
`;

export const GET_HISTORICAL_PRICE = gql`
  query GetHistoricalPrice(
    $input: HistoricalPriceInput!
  ) {
    getHistoricalPrice(
      input: $input
    ) {
      historicalPrice
    }
  }
`;

export const CALCULATE_PROFIT_LOSS = gql`
  query CalculateProfitLoss($input: CalculateProfitLossInput!) {
    calculateProfitLoss(input: $input) {
      profitLoss
    }
  }
`;
