export const tableHeaders = [
  {
    key: "token",
    title: "Token",
    customStyle: {
      width: '15%',
      minWidth: '120px',
      position: 'sticky',
      left: 0,
      zIndex: 2
    },
  },
  {
    key: 'network',
    title: 'Network',
    customStyle: {
      width: '20%',
      minWidth: '140px'
    }
  },
  {
    key: 'issuers',
    title: 'Issuers',
    customStyle: {
      width: '25%',
      minWidth: '140px'
    }
  },
  {
    key: 'limit',
    title: 'Limit',
    customStyle: {
      width: '15%',
      minWidth: '150px',
      textAlign: 'right'
    }
  },
  {
    key: 'used',
    title: 'Used',
    customStyle: {
      width: '15%',
      minWidth: '150px',
      textAlign: 'right'
    }
  },
  {
    key: 'action',
    title: 'Action',
    customStyle: {
      width: '10%',
      minWidth: '100px',
      textAlign: 'center',
      justifyContent: 'center'
    }
  },
];

export const dummySpendingCaps = [
  {
    token: 'DAI',
    caps: [
      {
        network: 'Base',
        limit: '',
        used: ''
      },
      {
        network: 'Ethereum',
        limit: '',
        used: ''
      },
      {
        network: 'Polygon',
        limit: '',
        used: ''
      }
    ]
  },
  {
    token: 'EURC',
    caps: [
      {
        network: 'Base',
        limit: '',
        used: ''
      },
      {
        network: 'Ethereum',
        limit: '',
        used: ''
      }
    ]
  },
  {
    token: 'EURE',
    caps: [
      {
        network: 'Ethereum',
        limit: '',
        used: ''
      },
      {
        network: 'Polygon',
        limit: '',
        used: ''
      }
    ]
  },
  {
    token: 'USDC',
    caps: [
      {
        network: 'Base',
        limit: '',
        used: ''
      },
      {
        network: 'Ethereum',
        limit: '',
        used: ''
      },
      {
        network: 'Polygon',
        limit: '',
        used: ''
      }
    ]
  },
  {
    token: 'USDT',
    caps: [
      {
        network: 'Base',
        limit: '',
        used: ''
      },
      {
        network: 'Ethereum',
        limit: '',
        used: ''
      },
      {
        network: 'Polygon',
        limit: '',
        used: ''
      }
    ]
  },
  {
    token: 'XSGD',
    caps: [
      {
        network: 'Ethereum',
        limit: '',
        used: ''
      }
    ]
  },
  {
    token: 'ZCHF',
    caps: [
      {
        network: 'Ethereum',
        limit: '',
        used: ''
      }
    ]
  }
];
